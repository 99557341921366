/*!
 * Bootstrap v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue:#007bff;
  --indigo:#6610f2;
  --purple:#6f42c1;
  --pink:#e83e8c;
  --red:#dc3545;
  --orange:#fd7e14;
  --yellow:#ffc107;
  --green:#28a745;
  --teal:#20c997;
  --cyan:#17a2b8;
  --white:#fff;
  --gray:#6c757d;
  --gray-dark:#343a40;
  --primary:#007bff;
  --secondary:#6c757d;
  --success:#28a745;
  --info:#17a2b8;
  --warning:#ffc107;
  --danger:#dc3545;
  --light:#f8f9fa;
  --dark:#343a40;
  --breakpoint-xs:0;
  --breakpoint-sm:576px;
  --breakpoint-md:768px;
  --breakpoint-lg:992px;
  --breakpoint-xl:1200px;
  --font-family-sans-serif:-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --font-family-monospace:SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace; }

*, ::after, ::before {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent; }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[data-original-title], abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b, strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }

a:hover {
  color: #0056b3;
  text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

code, kbd, pre, samp {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: .75rem;
  padding-bottom: .75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: .5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button, input {
  overflow: visible; }

button, select {
  text-transform: none; }

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button; }

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0; }

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.2;
  color: inherit; }

.h1, h1 {
  font-size: 2.5rem; }

.h2, h2 {
  font-size: 2rem; }

.h3, h3 {
  font-size: 1.75rem; }

.h4, h4 {
  font-size: 1.5rem; }

.h5, h5 {
  font-size: 1.25rem; }

.h6, h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

.small, small {
  font-size: 80%;
  font-weight: 400; }

.mark, mark {
  padding: .2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }

.list-inline-item:not(:last-child) {
  margin-right: .5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }

.blockquote-footer::before {
  content: "\2014\A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: .25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: .5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }

a > code {
  color: inherit; }

kbd {
  padding: .2rem .4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: .2rem; }

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col, .no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0; }

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.333333%; }

.offset-2 {
  margin-left: 16.666667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.333333%; }

.offset-5 {
  margin-left: 41.666667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.333333%; }

.offset-8 {
  margin-left: 66.666667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.333333%; }

.offset-11 {
  margin-left: 91.666667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.333333%; }
  .offset-sm-2 {
    margin-left: 16.666667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.333333%; }
  .offset-sm-5 {
    margin-left: 41.666667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.333333%; }
  .offset-sm-8 {
    margin-left: 66.666667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.333333%; }
  .offset-sm-11 {
    margin-left: 91.666667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.333333%; }
  .offset-md-2 {
    margin-left: 16.666667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.333333%; }
  .offset-md-5 {
    margin-left: 41.666667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.333333%; }
  .offset-md-8 {
    margin-left: 66.666667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.333333%; }
  .offset-md-11 {
    margin-left: 91.666667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.333333%; }
  .offset-lg-2 {
    margin-left: 16.666667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.333333%; }
  .offset-lg-5 {
    margin-left: 41.666667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.333333%; }
  .offset-lg-8 {
    margin-left: 66.666667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.333333%; }
  .offset-lg-11 {
    margin-left: 91.666667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.333333%; }
  .offset-xl-2 {
    margin-left: 16.666667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.333333%; }
  .offset-xl-5 {
    margin-left: 41.666667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.333333%; }
  .offset-xl-8 {
    margin-left: 66.666667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.333333%; }
  .offset-xl-11 {
    margin-left: 91.666667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent; }

.table td, .table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6; }

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6; }

.table tbody + tbody {
  border-top: 2px solid #dee2e6; }

.table .table {
  background-color: #fff; }

.table-sm td, .table-sm th {
  padding: .3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }

.table-bordered td, .table-bordered th {
  border: 1px solid #dee2e6; }

.table-bordered thead td, .table-bordered thead th {
  border-bottom-width: 2px; }

.table-borderless tbody + tbody, .table-borderless td, .table-borderless th, .table-borderless thead th {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary, .table-primary > td, .table-primary > th {
  background-color: #b8daff; }

.table-primary tbody + tbody, .table-primary td, .table-primary th, .table-primary thead th {
  border-color: #7abaff; }

.table-hover .table-primary:hover {
  background-color: #9fcdff; }

.table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
  background-color: #9fcdff; }

.table-secondary, .table-secondary > td, .table-secondary > th {
  background-color: #d6d8db; }

.table-secondary tbody + tbody, .table-secondary td, .table-secondary th, .table-secondary thead th {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }

.table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
  background-color: #c8cbcf; }

.table-success, .table-success > td, .table-success > th {
  background-color: #c3e6cb; }

.table-success tbody + tbody, .table-success td, .table-success th, .table-success thead th {
  border-color: #8fd19e; }

.table-hover .table-success:hover {
  background-color: #b1dfbb; }

.table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
  background-color: #b1dfbb; }

.table-info, .table-info > td, .table-info > th {
  background-color: #bee5eb; }

.table-info tbody + tbody, .table-info td, .table-info th, .table-info thead th {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }

.table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
  background-color: #abdde5; }

.table-warning, .table-warning > td, .table-warning > th {
  background-color: #ffeeba; }

.table-warning tbody + tbody, .table-warning td, .table-warning th, .table-warning thead th {
  border-color: #ffdf7e; }

.table-hover .table-warning:hover {
  background-color: #ffe8a1; }

.table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
  background-color: #ffe8a1; }

.table-danger, .table-danger > td, .table-danger > th {
  background-color: #f5c6cb; }

.table-danger tbody + tbody, .table-danger td, .table-danger th, .table-danger thead th {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }

.table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
  background-color: #f1b0b7; }

.table-light, .table-light > td, .table-light > th {
  background-color: #fdfdfe; }

.table-light tbody + tbody, .table-light td, .table-light th, .table-light thead th {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }

.table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
  background-color: #ececf6; }

.table-dark, .table-dark > td, .table-dark > th {
  background-color: #c6c8ca; }

.table-dark tbody + tbody, .table-dark td, .table-dark th, .table-dark thead th {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }

.table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
  background-color: #b9bbbe; }

.table-active, .table-active > td, .table-active > th {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #212529;
  border-color: #32383e; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #212529; }

.table-dark td, .table-dark th, .table-dark thead th {
  border-color: #32383e; }

.table-dark.table-bordered {
  border: 0; }

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05); }

.table-dark.table-hover tbody tr:hover {
  background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-sm > .table-bordered {
    border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-md > .table-bordered {
    border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-lg > .table-bordered {
    border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive-xl > .table-bordered {
    border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

.table-responsive > .table-bordered {
  border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

@media screen and (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none; } }

.form-control::-ms-expand {
  background-color: transparent;
  border: 0; }

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control::placeholder {
  color: #6c757d;
  opacity: 1; }

.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file, .form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(.25rem + 1px);
  padding-bottom: calc(.25rem + 1px);
  font-size: .875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: .375rem;
  padding-bottom: .375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0; }

.form-control-sm {
  height: calc(1.8125rem + 2px);
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem; }

.form-control-lg {
  height: calc(2.875rem + 2px);
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem; }

select.form-control[multiple], select.form-control[size] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: .25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }

.form-row > .col, .form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: .3rem;
  margin-left: -1.25rem; }

.form-check-input:disabled ~ .form-check-label {
  color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: .75rem; }

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: .3125rem;
  margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: .25rem; }

.form-control.is-valid, .was-validated .form-control:valid {
  border-color: #28a745;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"); }

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.form-control.is-valid ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip, .was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control:valid ~ .valid-tooltip {
  display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4); }

.custom-select.is-valid, .was-validated .custom-select:valid {
  border-color: #28a745;
  padding-right: 3.4375rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") no-repeat center right 1.75rem/1.125rem 1.125rem; }

.custom-select.is-valid:focus, .was-validated .custom-select:valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.custom-select.is-valid ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip, .was-validated .custom-select:valid ~ .valid-feedback, .was-validated .custom-select:valid ~ .valid-tooltip {
  display: block; }

.form-control-file.is-valid ~ .valid-feedback, .form-control-file.is-valid ~ .valid-tooltip, .was-validated .form-control-file:valid ~ .valid-feedback, .was-validated .form-control-file:valid ~ .valid-tooltip {
  display: block; }

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #28a745; }

.form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip, .was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip {
  display: block; }

.custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-control-input:valid ~ .custom-control-label {
  color: #28a745; }

.custom-control-input.is-valid ~ .custom-control-label::before, .was-validated .custom-control-input:valid ~ .custom-control-label::before {
  border-color: #28a745; }

.custom-control-input.is-valid ~ .valid-feedback, .custom-control-input.is-valid ~ .valid-tooltip, .was-validated .custom-control-input:valid ~ .valid-feedback, .was-validated .custom-control-input:valid ~ .valid-tooltip {
  display: block; }

.custom-control-input.is-valid:checked ~ .custom-control-label::before, .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.custom-control-input.is-valid:focus ~ .custom-control-label::before, .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before, .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.custom-file-input.is-valid ~ .custom-file-label, .was-validated .custom-file-input:valid ~ .custom-file-label {
  border-color: #28a745; }

.custom-file-input.is-valid ~ .valid-feedback, .custom-file-input.is-valid ~ .valid-tooltip, .was-validated .custom-file-input:valid ~ .valid-feedback, .was-validated .custom-file-input:valid ~ .valid-tooltip {
  display: block; }

.custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: .25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .25rem .5rem;
  margin-top: .1rem;
  font-size: .875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: .25rem; }

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #dc3545;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right calc(2.25rem / 4);
  background-size: calc(2.25rem / 2) calc(2.25rem / 2);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"); }

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip {
  display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 2.25rem;
  background-position: top calc(2.25rem / 4) right calc(2.25rem / 4); }

.custom-select.is-invalid, .was-validated .custom-select:invalid {
  border-color: #dc3545;
  padding-right: 3.4375rem;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") no-repeat center right 1.75rem/1.125rem 1.125rem; }

.custom-select.is-invalid:focus, .was-validated .custom-select:invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip, .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .custom-select:invalid ~ .invalid-tooltip {
  display: block; }

.form-control-file.is-invalid ~ .invalid-feedback, .form-control-file.is-invalid ~ .invalid-tooltip, .was-validated .form-control-file:invalid ~ .invalid-feedback, .was-validated .form-control-file:invalid ~ .invalid-tooltip {
  display: block; }

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #dc3545; }

.form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip, .was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip {
  display: block; }

.custom-control-input.is-invalid ~ .custom-control-label, .was-validated .custom-control-input:invalid ~ .custom-control-label {
  color: #dc3545; }

.custom-control-input.is-invalid ~ .custom-control-label::before, .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
  border-color: #dc3545; }

.custom-control-input.is-invalid ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-tooltip, .was-validated .custom-control-input:invalid ~ .invalid-feedback, .was-validated .custom-control-input:invalid ~ .invalid-tooltip {
  display: block; }

.custom-control-input.is-invalid:checked ~ .custom-control-label::before, .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.custom-control-input.is-invalid:focus ~ .custom-control-label::before, .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before, .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.custom-file-input.is-invalid ~ .custom-file-label, .was-validated .custom-file-input:invalid ~ .custom-file-label {
  border-color: #dc3545; }

.custom-file-input.is-invalid ~ .invalid-feedback, .custom-file-input.is-invalid ~ .invalid-tooltip, .was-validated .custom-file-input:invalid ~ .invalid-feedback, .was-validated .custom-file-input:invalid ~ .invalid-tooltip {
  display: block; }

.custom-file-input.is-invalid:focus ~ .custom-file-label, .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }

.form-inline .form-check {
  width: 100%; }

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0; }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0; }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-inline .form-control-plaintext {
    display: inline-block; }
  .form-inline .custom-select, .form-inline .input-group {
    width: auto; }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0; }
  .form-inline .form-check-input {
    position: relative;
    margin-top: 0;
    margin-right: .25rem;
    margin-left: 0; }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center; }
  .form-inline .custom-control-label {
    margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none; } }

.btn:hover {
  color: #212529;
  text-decoration: none; }

.btn.focus, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.btn.disabled, .btn:disabled {
  opacity: .65; }

.btn:not(:disabled):not(.disabled) {
  cursor: pointer; }

a.btn.disabled, fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc; }

.btn-primary.focus, .btn-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf; }

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); }

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62; }

.btn-secondary.focus, .btn-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b; }

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0.5); }

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34; }

.btn-success.focus, .btn-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430; }

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b; }

.btn-info.focus, .btn-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f; }

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00; }

.btn-warning.focus, .btn-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500; }

.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130; }

.btn-danger.focus, .btn-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d; }

.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5; }

.btn-light.focus, .btn-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df; }

.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124; }

.btn-dark.focus, .btn-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d; }

.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5); }

.btn-outline-primary {
  color: #007bff;
  border-color: #007bff; }

.btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-outline-primary.focus, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #007bff;
  background-color: transparent; }

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5); }

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d; }

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-outline-secondary.focus, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: transparent; }

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.btn-outline-success {
  color: #28a745;
  border-color: #28a745; }

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-outline-success.focus, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent; }

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745; }

.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-outline-info.focus, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent; }

.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }

.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107; }

.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-outline-warning.focus, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent; }

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107; }

.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger.focus, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent; }

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }

.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-light.focus, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent; }

.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }

.btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #343a40;
  border-color: #343a40; }

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-outline-dark.focus, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent; }

.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40; }

.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.btn-link {
  font-weight: 400;
  color: #007bff; }

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline; }

.btn-link.focus, .btn-link:focus {
  text-decoration: underline;
  box-shadow: none; }

.btn-link.disabled, .btn-link:disabled {
  color: #6c757d;
  pointer-events: none; }

.btn-group-lg > .btn, .btn-lg {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem; }

.btn-group-sm > .btn, .btn-sm {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem; }

.btn-block {
  display: block;
  width: 100%; }

.btn-block + .btn-block {
  margin-top: .5rem; }

input[type=button].btn-block, input[type=reset].btn-block, input[type=submit].btn-block {
  width: 100%; }

.fade {
  transition: opacity .15s linear; }

@media screen and (prefers-reduced-motion: reduce) {
  .fade {
    transition: none; } }

.fade:not(.show) {
  opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height .35s ease; }

@media screen and (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none; } }

.dropdown, .dropleft, .dropright, .dropup {
  position: relative; }

.dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent; }

.dropdown-toggle:empty::after {
  margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: .5rem 0;
  margin: .125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: .25rem; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropdown-menu-left {
  right: auto;
  left: 0; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: .125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: 0;
  border-right: .3em solid transparent;
  border-bottom: .3em solid;
  border-left: .3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: .125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: 0;
  border-bottom: .3em solid transparent;
  border-left: .3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: .125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: .255em;
  vertical-align: .255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: .255em;
  vertical-align: .255em;
  content: "";
  border-top: .3em solid transparent;
  border-right: .3em solid;
  border-bottom: .3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=top] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }

.dropdown-item:first-child {
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px); }

.dropdown-item:last-child {
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px); }

.dropdown-item:focus, .dropdown-item:hover {
  color: #16181b;
  text-decoration: none;
  background-color: #f8f9fa; }

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #007bff; }

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: .5rem 1.5rem;
  margin-bottom: 0;
  font-size: .875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: .25rem 1.5rem;
  color: #212529; }

.btn-group, .btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }

.btn-group-vertical > .btn, .btn-group > .btn {
  position: relative;
  flex: 1 1 auto; }

.btn-group-vertical > .btn:hover, .btn-group > .btn:hover {
  z-index: 1; }

.btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus {
  z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }

.btn-toolbar .input-group {
  width: auto; }

.btn-group > .btn-group:not(:first-child), .btn-group > .btn:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: .5625rem;
  padding-left: .5625rem; }

.dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after {
  margin-left: 0; }

.dropleft .dropdown-toggle-split::before {
  margin-right: 0; }

.btn-group-sm > .btn + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
  padding-right: .375rem;
  padding-left: .375rem; }

.btn-group-lg > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split {
  padding-right: .75rem;
  padding-left: .75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%; }

.btn-group-vertical > .btn-group:not(:first-child), .btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px; }

.btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }

.btn-group-toggle > .btn input[type=checkbox], .btn-group-toggle > .btn input[type=radio], .btn-group-toggle > .btn-group > .btn input[type=checkbox], .btn-group-toggle > .btn-group > .btn input[type=radio] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }

.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control, .input-group > .form-control-plaintext {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0; }

.input-group > .custom-file + .custom-file, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .form-control, .input-group > .custom-select + .custom-file, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .form-control, .input-group > .form-control + .custom-file, .input-group > .form-control + .custom-select, .input-group > .form-control + .form-control, .input-group > .form-control-plaintext + .custom-file, .input-group > .form-control-plaintext + .custom-select, .input-group > .form-control-plaintext + .form-control {
  margin-left: -1px; }

.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label, .input-group > .custom-select:focus, .input-group > .form-control:focus {
  z-index: 3; }

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4; }

.input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group > .custom-file {
  display: flex;
  align-items: center; }

.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.input-group-append, .input-group-prepend {
  display: flex; }

.input-group-append .btn, .input-group-prepend .btn {
  position: relative;
  z-index: 2; }

.input-group-append .btn:focus, .input-group-prepend .btn:focus {
  z-index: 3; }

.input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .btn, .input-group-append .input-group-text + .input-group-text, .input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-prepend .input-group-text + .input-group-text {
  margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: .25rem; }

.input-group-text input[type=checkbox], .input-group-text input[type=radio] {
  margin-top: 0; }

.input-group-lg > .custom-select, .input-group-lg > .form-control:not(textarea) {
  height: calc(2.875rem + 2px); }

.input-group-lg > .custom-select, .input-group-lg > .form-control, .input-group-lg > .input-group-append > .btn, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-prepend > .input-group-text {
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: .3rem; }

.input-group-sm > .custom-select, .input-group-sm > .form-control:not(textarea) {
  height: calc(1.8125rem + 2px); }

.input-group-sm > .custom-select, .input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
  border-radius: .2rem; }

.input-group-lg > .custom-select, .input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff; }

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff; }

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff; }

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d; }

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }

.custom-control-label::before {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px; }

.custom-control-label::after {
  position: absolute;
  top: .25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: .25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: .5rem; }

.custom-switch .custom-control-label::after {
  top: calc(.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: .5rem;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out;
  transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: transform .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-transform .15s ease-in-out; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none; } }

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem); }

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-appearance: none;
  appearance: none; }

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5); }

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: .75rem;
  background-image: none; }

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef; }

.custom-select::-ms-expand {
  opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: .25rem;
  padding-bottom: .25rem;
  padding-left: .5rem;
  font-size: .875rem; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef; }

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse"; }

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: .375rem .75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem; }

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 .25rem .25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + .4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  appearance: none; }

.custom-range:focus {
  outline: 0; }

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-range::-moz-focus-outer {
  border: 0; }

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none; } }

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff; }

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem; }

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  appearance: none; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none; } }

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff; }

.custom-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem; }

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: .2rem;
  margin-left: .2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  appearance: none; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    -ms-transition: none;
    transition: none; } }

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff; }

.custom-range::-ms-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: .5rem; }

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem; }

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem; }

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd; }

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default; }

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd; }

.custom-range:disabled::-moz-range-track {
  cursor: default; }

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd; }

.custom-control-label::before, .custom-file-label, .custom-select {
  transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before, .custom-file-label, .custom-select {
    transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: .5rem 1rem; }

.nav-link:focus, .nav-link:hover {
  text-decoration: none; }

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }

.nav-tabs .nav-item {
  margin-bottom: -1px; }

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: #e9ecef #e9ecef #dee2e6; }

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent; }

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff; }

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: .25rem; }

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 1rem; }

.navbar > .container, .navbar > .container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }

.navbar-brand:focus, .navbar-brand:hover {
  text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0; }

.navbar-nav .dropdown-menu {
  position: static;
  float: none; }

.navbar-text {
  display: inline-block;
  padding-top: .5rem;
  padding-bottom: .5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .25rem; }

.navbar-toggler:focus, .navbar-toggler:hover {
  text-decoration: none; }

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row; }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-sm .navbar-toggler {
    display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
  .navbar-expand-md .navbar-nav {
    flex-direction: row; }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-md .navbar-toggler {
    display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row; }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-lg .navbar-toggler {
    display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row; }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem; }
  .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand-xl .navbar-toggler {
    display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }

.navbar-expand > .container, .navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0; }

.navbar-expand .navbar-nav {
  flex-direction: row; }

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute; }

.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem; }

.navbar-expand > .container, .navbar-expand > .container-fluid {
  flex-wrap: nowrap; }

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto; }

.navbar-expand .navbar-toggler {
  display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: rgba(0, 0, 0, 0.7); }

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
  color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }

.navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, 0.75); }

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show > .nav-link {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }

.navbar-dark .navbar-text a {
  color: #fff; }

.navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: .25rem; }

.card > hr {
  margin-right: 0;
  margin-left: 0; }

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: .75rem; }

.card-subtitle {
  margin-top: -.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  color: inherit;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }

.card-header:first-child {
  border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0; }

.card-header + .list-group .list-group-item:first-child {
  border-top: 0; }

.card-footer {
  padding: .75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }

.card-footer:last-child {
  border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px); }

.card-header-tabs {
  margin-right: -.625rem;
  margin-bottom: -.75rem;
  margin-left: -.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -.625rem;
  margin-left: -.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(.25rem - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .card-deck .card {
    display: flex;
    flex: 1 0;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    flex-flow: row wrap; }
  .card-group > .card {
    flex: 1 0;
    margin-bottom: 0; }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0; }
  .card-group > .card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .card-group > .card:first-child .card-header, .card-group > .card:first-child .card-img-top {
    border-top-right-radius: 0; }
  .card-group > .card:first-child .card-footer, .card-group > .card:first-child .card-img-bottom {
    border-bottom-right-radius: 0; }
  .card-group > .card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .card-group > .card:last-child .card-header, .card-group > .card:last-child .card-img-top {
    border-top-left-radius: 0; }
  .card-group > .card:last-child .card-footer, .card-group > .card:last-child .card-img-bottom {
    border-bottom-left-radius: 0; }
  .card-group > .card:only-child {
    border-radius: .25rem; }
  .card-group > .card:only-child .card-header, .card-group > .card:only-child .card-img-top {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem; }
  .card-group > .card:only-child .card-footer, .card-group > .card:only-child .card-img-bottom {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem; }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0; }
  .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom, .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top {
    border-radius: 0; } }

.card-columns .card {
  margin-bottom: .75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
  .card-columns .card {
    display: inline-block;
    width: 100%; } }

.accordion .card {
  overflow: hidden; }

.accordion .card:not(:first-of-type) .card-header:first-child {
  border-radius: 0; }

.accordion .card:not(:first-of-type):not(:last-of-type) {
  border-bottom: 0;
  border-radius: 0; }

.accordion .card:first-of-type {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.accordion .card:last-of-type {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.accordion .card .card-header {
  margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: .75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: .25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: .5rem; }

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: .5rem;
  color: #6c757d;
  content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: .25rem; }

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-link:not(:disabled):not(.disabled) {
  cursor: pointer; }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem; }

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: .75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .3rem;
  border-bottom-left-radius: .3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem; }

.pagination-sm .page-link {
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem; }

.badge {
  display: inline-block;
  padding: .25em .4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem; }

a.badge:focus, a.badge:hover {
  text-decoration: none; }

.badge:empty {
  display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: .6em;
  padding-left: .6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #007bff; }

a.badge-primary:focus, a.badge-primary:hover {
  color: #fff;
  background-color: #0062cc; }

.badge-secondary {
  color: #fff;
  background-color: #6c757d; }

a.badge-secondary:focus, a.badge-secondary:hover {
  color: #fff;
  background-color: #545b62; }

.badge-success {
  color: #fff;
  background-color: #28a745; }

a.badge-success:focus, a.badge-success:hover {
  color: #fff;
  background-color: #1e7e34; }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }

a.badge-info:focus, a.badge-info:hover {
  color: #fff;
  background-color: #117a8b; }

.badge-warning {
  color: #212529;
  background-color: #ffc107; }

a.badge-warning:focus, a.badge-warning:hover {
  color: #212529;
  background-color: #d39e00; }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }

a.badge-danger:focus, a.badge-danger:hover {
  color: #fff;
  background-color: #bd2130; }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }

a.badge-light:focus, a.badge-light:hover {
  color: #212529;
  background-color: #dae0e5; }

.badge-dark {
  color: #fff;
  background-color: #343a40; }

a.badge-dark:focus, a.badge-dark:hover {
  color: #fff;
  background-color: #1d2124; }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: .3rem; }

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: .75rem 1.25rem;
  color: inherit; }

.alert-primary {
  color: #004085;
  background-color: #cce5ff;
  border-color: #b8daff; }

.alert-primary hr {
  border-top-color: #9fcdff; }

.alert-primary .alert-link {
  color: #002752; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }

.alert-secondary hr {
  border-top-color: #c8cbcf; }

.alert-secondary .alert-link {
  color: #202326; }

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb; }

.alert-success hr {
  border-top-color: #b1dfbb; }

.alert-success .alert-link {
  color: #0b2e13; }

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb; }

.alert-info hr {
  border-top-color: #abdde5; }

.alert-info .alert-link {
  color: #062c33; }

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba; }

.alert-warning hr {
  border-top-color: #ffe8a1; }

.alert-warning .alert-link {
  color: #533f03; }

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb; }

.alert-danger hr {
  border-top-color: #f1b0b7; }

.alert-danger .alert-link {
  color: #491217; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }

.alert-light hr {
  border-top-color: #ececf6; }

.alert-light .alert-link {
  color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }

.alert-dark hr {
  border-top-color: #b9bbbe; }

.alert-dark .alert-link {
  color: #040505; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: .75rem;
  background-color: #e9ecef;
  border-radius: .25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #007bff;
  transition: width .6s ease; }

@media screen and (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }

.list-group-item-action:focus, .list-group-item-action:hover {
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa; }

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: .75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }

.list-group-item:first-child {
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem; }

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem; }

.list-group-item:focus, .list-group-item:hover {
  z-index: 1;
  text-decoration: none; }

.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff; }

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }

.list-group-flush .list-group-item:last-child {
  margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff; }

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  color: #004085;
  background-color: #9fcdff; }

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  color: #383d41;
  background-color: #c8cbcf; }

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41; }

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb; }

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  color: #155724;
  background-color: #b1dfbb; }

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  color: #0c5460;
  background-color: #abdde5; }

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460; }

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba; }

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  color: #856404;
  background-color: #ffe8a1; }

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  color: #721c24;
  background-color: #f1b0b7; }

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  color: #818182;
  background-color: #ececf6; }

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  color: #1b1e21;
  background-color: #b9bbbe; }

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }

.close:hover {
  color: #000;
  text-decoration: none; }

.close:not(:disabled):not(.disabled) {
  cursor: pointer; }

.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
  opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: .875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0; }

.toast:not(:last-child) {
  margin-bottom: .75rem; }

.toast.showing {
  opacity: 1; }

.toast.show {
  display: block;
  opacity: 1; }

.toast.hide {
  display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: .25rem .75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: .75rem; }

.modal-open {
  overflow: hidden; }

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none; }

.modal.fade .modal-dialog {
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  transition: transform .3s ease-out,-webkit-transform .3s ease-out;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px); }

@media screen and (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none; } }

.modal.show .modal-dialog {
  -webkit-transform: none;
  transform: none; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (.5rem * 2)); }

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - (.5rem * 2));
  content: ""; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }

.modal-backdrop.fade {
  opacity: 0; }

.modal-backdrop.show {
  opacity: .5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #e9ecef;
  border-top-left-radius: .3rem;
  border-top-right-radius: .3rem; }

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #e9ecef;
  border-bottom-right-radius: .3rem;
  border-bottom-left-radius: .3rem; }

.modal-footer > :not(:first-child) {
  margin-left: .25rem; }

.modal-footer > :not(:last-child) {
  margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-centered {
    min-height: calc(100% - (1.75rem * 2)); }
  .modal-dialog-centered::before {
    height: calc(100vh - (1.75rem * 2)); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  opacity: 0; }

.tooltip.show {
  opacity: .9; }

.tooltip .arrow {
  position: absolute;
  display: block;
  width: .8rem;
  height: .4rem; }

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-tooltip-auto[x-placement^=top], .bs-tooltip-top {
  padding: .4rem 0; }

.bs-tooltip-auto[x-placement^=top] .arrow, .bs-tooltip-top .arrow {
  bottom: 0; }

.bs-tooltip-auto[x-placement^=top] .arrow::before, .bs-tooltip-top .arrow::before {
  top: 0;
  border-width: .4rem .4rem 0;
  border-top-color: #000; }

.bs-tooltip-auto[x-placement^=right], .bs-tooltip-right {
  padding: 0 .4rem; }

.bs-tooltip-auto[x-placement^=right] .arrow, .bs-tooltip-right .arrow {
  left: 0;
  width: .4rem;
  height: .8rem; }

.bs-tooltip-auto[x-placement^=right] .arrow::before, .bs-tooltip-right .arrow::before {
  right: 0;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #000; }

.bs-tooltip-auto[x-placement^=bottom], .bs-tooltip-bottom {
  padding: .4rem 0; }

.bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-bottom .arrow {
  top: 0; }

.bs-tooltip-auto[x-placement^=bottom] .arrow::before, .bs-tooltip-bottom .arrow::before {
  bottom: 0;
  border-width: 0 .4rem .4rem;
  border-bottom-color: #000; }

.bs-tooltip-auto[x-placement^=left], .bs-tooltip-left {
  padding: 0 .4rem; }

.bs-tooltip-auto[x-placement^=left] .arrow, .bs-tooltip-left .arrow {
  right: 0;
  width: .4rem;
  height: .8rem; }

.bs-tooltip-auto[x-placement^=left] .arrow::before, .bs-tooltip-left .arrow::before {
  left: 0;
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: .25rem .5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: .25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem; }

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: .5rem;
  margin: 0 .3rem; }

.popover .arrow::after, .popover .arrow::before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid; }

.bs-popover-auto[x-placement^=top], .bs-popover-top {
  margin-bottom: .5rem; }

.bs-popover-auto[x-placement^=top] .arrow, .bs-popover-top .arrow {
  bottom: calc((.5rem + 1px) * -1); }

.bs-popover-auto[x-placement^=top] .arrow::after, .bs-popover-auto[x-placement^=top] .arrow::before, .bs-popover-top .arrow::after, .bs-popover-top .arrow::before {
  border-width: .5rem .5rem 0; }

.bs-popover-auto[x-placement^=top] .arrow::before, .bs-popover-top .arrow::before {
  bottom: 0;
  border-top-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=top] .arrow::after, .bs-popover-top .arrow::after {
  bottom: 1px;
  border-top-color: #fff; }

.bs-popover-auto[x-placement^=right], .bs-popover-right {
  margin-left: .5rem; }

.bs-popover-auto[x-placement^=right] .arrow, .bs-popover-right .arrow {
  left: calc((.5rem + 1px) * -1);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0; }

.bs-popover-auto[x-placement^=right] .arrow::after, .bs-popover-auto[x-placement^=right] .arrow::before, .bs-popover-right .arrow::after, .bs-popover-right .arrow::before {
  border-width: .5rem .5rem .5rem 0; }

.bs-popover-auto[x-placement^=right] .arrow::before, .bs-popover-right .arrow::before {
  left: 0;
  border-right-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=right] .arrow::after, .bs-popover-right .arrow::after {
  left: 1px;
  border-right-color: #fff; }

.bs-popover-auto[x-placement^=bottom], .bs-popover-bottom {
  margin-top: .5rem; }

.bs-popover-auto[x-placement^=bottom] .arrow, .bs-popover-bottom .arrow {
  top: calc((.5rem + 1px) * -1); }

.bs-popover-auto[x-placement^=bottom] .arrow::after, .bs-popover-auto[x-placement^=bottom] .arrow::before, .bs-popover-bottom .arrow::after, .bs-popover-bottom .arrow::before {
  border-width: 0 .5rem .5rem .5rem; }

.bs-popover-auto[x-placement^=bottom] .arrow::before, .bs-popover-bottom .arrow::before {
  top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=bottom] .arrow::after, .bs-popover-bottom .arrow::after {
  top: 1px;
  border-bottom-color: #fff; }

.bs-popover-auto[x-placement^=bottom] .popover-header::before, .bs-popover-bottom .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7; }

.bs-popover-auto[x-placement^=left], .bs-popover-left {
  margin-right: .5rem; }

.bs-popover-auto[x-placement^=left] .arrow, .bs-popover-left .arrow {
  right: calc((.5rem + 1px) * -1);
  width: .5rem;
  height: 1rem;
  margin: .3rem 0; }

.bs-popover-auto[x-placement^=left] .arrow::after, .bs-popover-auto[x-placement^=left] .arrow::before, .bs-popover-left .arrow::after, .bs-popover-left .arrow::before {
  border-width: .5rem 0 .5rem .5rem; }

.bs-popover-auto[x-placement^=left] .arrow::before, .bs-popover-left .arrow::before {
  right: 0;
  border-left-color: rgba(0, 0, 0, 0.25); }

.bs-popover-auto[x-placement^=left] .arrow::after, .bs-popover-left .arrow::after {
  right: 1px;
  border-left-color: #fff; }

.popover-header {
  padding: .5rem .75rem;
  margin-bottom: 0;
  font-size: 1rem;
  color: inherit;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(.3rem - 1px);
  border-top-right-radius: calc(.3rem - 1px); }

.popover-header:empty {
  display: none; }

.popover-body {
  padding: .5rem .75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }

.carousel-inner::after {
  display: block;
  clear: both;
  content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform .6s ease-in-out;
  transition: transform .6s ease-in-out;
  transition: transform .6s ease-in-out, -webkit-transform .6s ease-in-out;
  transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out; }

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none; } }

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block; }

.active.carousel-item-right, .carousel-item-next:not(.carousel-item-left) {
  -webkit-transform: translateX(100%);
  transform: translateX(100%); }

.active.carousel-item-left, .carousel-item-prev:not(.carousel-item-right) {
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
  transform: none; }

.carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right, .carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s .6s opacity; }

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
    transition: none; } }

.carousel-control-next, .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  transition: opacity .15s ease; }

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-control-next, .carousel-control-prev {
    transition: none; } }

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-next-icon, .carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent no-repeat center center;
  background-size: 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease; }

@media screen and (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none; } }

.carousel-indicators .active {
  opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:focus, a.bg-secondary:hover, button.bg-secondary:focus, button.bg-secondary:hover {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:focus, a.bg-success:hover, button.bg-success:focus, button.bg-success:hover {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:focus, a.bg-info:hover, button.bg-info:focus, button.bg-info:hover {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:focus, a.bg-warning:hover, button.bg-warning:focus, button.bg-warning:hover {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:focus, a.bg-danger:hover, button.bg-danger:focus, button.bg-danger:hover {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:focus, a.bg-light:hover, button.bg-light:focus, button.bg-light:hover {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:focus, a.bg-dark:hover, button.bg-dark:focus, button.bg-dark:hover {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }

.embed-responsive::before {
  display: block;
  content: ""; }

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.857143%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-3by4::before {
  padding-top: 133.333333%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0, .my-0 {
  margin-top: 0 !important; }

.mr-0, .mx-0 {
  margin-right: 0 !important; }

.mb-0, .my-0 {
  margin-bottom: 0 !important; }

.ml-0, .mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1, .my-1 {
  margin-top: 0.25rem !important; }

.mr-1, .mx-1 {
  margin-right: 0.25rem !important; }

.mb-1, .my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1, .mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2, .my-2 {
  margin-top: 0.5rem !important; }

.mr-2, .mx-2 {
  margin-right: 0.5rem !important; }

.mb-2, .my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2, .mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3, .my-3 {
  margin-top: 1rem !important; }

.mr-3, .mx-3 {
  margin-right: 1rem !important; }

.mb-3, .my-3 {
  margin-bottom: 1rem !important; }

.ml-3, .mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4, .my-4 {
  margin-top: 1.5rem !important; }

.mr-4, .mx-4 {
  margin-right: 1.5rem !important; }

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4, .mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5, .my-5 {
  margin-top: 3rem !important; }

.mr-5, .mx-5 {
  margin-right: 3rem !important; }

.mb-5, .my-5 {
  margin-bottom: 3rem !important; }

.ml-5, .mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0, .py-0 {
  padding-top: 0 !important; }

.pr-0, .px-0 {
  padding-right: 0 !important; }

.pb-0, .py-0 {
  padding-bottom: 0 !important; }

.pl-0, .px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1, .py-1 {
  padding-top: 0.25rem !important; }

.pr-1, .px-1 {
  padding-right: 0.25rem !important; }

.pb-1, .py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1, .px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2, .py-2 {
  padding-top: 0.5rem !important; }

.pr-2, .px-2 {
  padding-right: 0.5rem !important; }

.pb-2, .py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2, .px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3, .py-3 {
  padding-top: 1rem !important; }

.pr-3, .px-3 {
  padding-right: 1rem !important; }

.pb-3, .py-3 {
  padding-bottom: 1rem !important; }

.pl-3, .px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4, .py-4 {
  padding-top: 1.5rem !important; }

.pr-4, .px-4 {
  padding-right: 1.5rem !important; }

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4, .px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5, .py-5 {
  padding-top: 3rem !important; }

.pr-5, .px-5 {
  padding-right: 3rem !important; }

.pb-5, .py-5 {
  padding-bottom: 3rem !important; }

.pl-5, .px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1, .my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1, .mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1, .my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1, .mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2, .my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2, .mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2, .my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2, .mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3, .my-n3 {
  margin-top: -1rem !important; }

.mr-n3, .mx-n3 {
  margin-right: -1rem !important; }

.mb-n3, .my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3, .mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4, .my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4, .mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4, .my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4, .mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5, .my-n5 {
  margin-top: -3rem !important; }

.mr-n5, .mx-n5 {
  margin-right: -3rem !important; }

.mb-n5, .my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5, .mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto, .my-auto {
  margin-top: auto !important; }

.mr-auto, .mx-auto {
  margin-right: auto !important; }

.mb-auto, .my-auto {
  margin-bottom: auto !important; }

.ml-auto, .mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0, .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0, .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0, .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0, .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1, .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1, .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1, .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1, .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2, .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2, .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2, .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2, .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3, .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3, .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3, .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3, .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4, .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4, .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4, .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4, .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5, .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5, .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5, .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5, .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0, .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0, .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0, .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0, .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1, .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1, .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1, .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1, .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2, .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2, .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2, .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2, .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3, .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3, .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3, .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3, .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4, .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4, .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4, .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4, .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5, .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5, .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5, .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5, .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1, .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1, .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1, .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1, .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2, .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2, .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2, .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2, .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3, .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3, .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3, .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3, .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4, .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4, .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4, .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4, .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5, .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5, .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5, .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5, .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto, .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto, .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto, .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto, .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0, .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0, .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0, .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0, .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1, .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1, .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1, .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1, .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2, .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2, .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2, .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2, .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3, .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3, .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3, .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3, .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4, .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4, .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4, .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4, .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5, .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5, .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5, .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5, .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0, .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0, .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0, .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0, .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1, .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1, .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1, .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1, .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2, .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2, .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2, .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2, .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3, .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3, .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3, .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3, .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4, .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4, .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4, .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4, .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5, .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5, .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5, .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5, .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1, .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1, .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1, .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1, .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2, .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2, .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2, .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2, .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3, .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3, .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3, .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3, .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4, .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4, .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4, .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4, .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5, .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5, .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5, .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5, .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto, .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto, .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto, .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto, .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0, .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0, .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0, .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0, .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1, .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1, .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1, .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1, .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2, .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2, .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2, .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2, .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3, .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3, .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3, .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3, .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4, .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4, .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4, .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4, .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5, .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5, .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5, .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5, .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0, .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0, .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0, .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0, .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1, .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1, .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1, .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1, .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2, .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2, .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2, .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2, .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3, .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3, .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3, .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3, .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4, .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4, .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4, .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4, .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5, .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5, .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5, .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5, .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1, .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1, .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1, .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1, .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2, .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2, .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2, .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2, .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3, .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3, .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3, .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3, .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4, .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4, .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4, .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4, .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5, .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5, .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5, .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5, .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto, .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto, .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto, .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto, .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0, .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0, .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0, .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0, .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1, .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1, .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1, .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1, .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2, .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2, .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2, .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2, .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3, .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3, .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3, .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3, .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4, .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4, .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4, .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4, .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5, .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5, .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5, .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5, .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0, .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0, .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0, .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0, .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1, .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1, .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1, .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1, .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2, .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2, .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2, .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2, .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3, .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3, .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3, .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3, .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4, .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4, .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4, .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4, .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5, .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5, .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5, .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5, .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1, .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1, .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1, .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1, .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2, .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2, .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2, .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2, .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3, .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3, .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3, .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3, .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4, .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4, .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4, .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4, .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5, .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5, .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5, .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5, .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto, .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto, .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto, .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto, .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:focus, a.text-primary:hover {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:focus, a.text-secondary:hover {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:focus, a.text-success:hover {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:focus, a.text-info:hover {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:focus, a.text-warning:hover {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:focus, a.text-danger:hover {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:focus, a.text-light:hover {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:focus, a.text-dark:hover {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *, ::after, ::before {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  blockquote, pre {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  img, tr {
    page-break-inside: avoid; }
  h2, h3, p {
    orphans: 3;
    widows: 3; }
  h2, h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
  .table td, .table th {
    background-color: #fff !important; }
  .table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
  .table-dark tbody + tbody, .table-dark td, .table-dark th, .table-dark thead th {
    border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

/*# sourceMappingURL=bootstrap.min.css.map */

@charset "UTF-8";
/*
    Flaticon icon font: Flaticon
    Creation date: 21/02/2019 13:06
    */
@font-face {
  font-family: "Flaticon";
  src: url(/static/media/Flaticon.6c61b057.eot);
  src: url(/static/media/Flaticon.6c61b057.eot?#iefix) format("embedded-opentype"), url(/static/media/Flaticon.dfb2f7d6.woff2) format("woff2"), url(/static/media/Flaticon.5ab2308e.woff) format("woff"), url(/static/media/Flaticon.42ddde84.ttf) format("truetype"), url(/static/media/Flaticon.18ffe77f.svg#Flaticon) format("svg");
  font-weight: normal;
  font-style: normal; }

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  @font-face {
    font-family: "Flaticon";
    src: url(/static/media/Flaticon.18ffe77f.svg#Flaticon) format("svg"); } }

.fi:before {
  display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased; }

.flaticon-bin:before {
  content: "\F100"; }

.flaticon-bold:before {
  content: "\F101"; }

.flaticon-italic:before {
  content: "\F102"; }

.flaticon-letter-u:before {
  content: "\F103"; }

.flaticon-text:before {
  content: "\F104"; }

.flaticon-right-align:before {
  content: "\F105"; }

.flaticon-text-1:before {
  content: "\F106"; }

.flaticon-text-2:before {
  content: "\F107"; }

.flaticon-text-3:before {
  content: "\F108"; }

.flaticon-order:before {
  content: "\F109"; }

.flaticon-menu:before {
  content: "\F10A"; }

.flaticon-table:before {
  content: "\F10B"; }

.flaticon-width:before {
  content: "\F10C"; }

.flaticon-typography:before {
  content: "\F10D"; }

.flaticon-art:before {
  content: "\F10E"; }

.flaticon-school:before {
  content: "\F10F"; }

.flaticon-edit:before {
  content: "\F110"; }

.flaticon-info:before {
  content: "\F111"; }

.flaticon-file:before {
  content: "\F112"; }

.flaticon-security:before {
  content: "\F113"; }

.flaticon-star:before {
  content: "\F114"; }

.flaticon-plus:before {
  content: "\F115"; }

.flaticon-width-1:before {
  content: "\F116"; }

.flaticon-height:before {
  content: "\F117"; }

.flaticon-hidden:before {
  content: "\F118"; }

.flaticon-document:before {
  content: "\F119"; }

.flaticon-save:before {
  content: "\F11A"; }

.flaticon-duplicate:before {
  content: "\F11B"; }

.flaticon-expand:before {
  content: "\F11C"; }

.flaticon-compress:before {
  content: "\F11D"; }

.flaticon-minimize:before {
  content: "\F11E"; }

.flaticon-expand-1:before {
  content: "\F11F"; }

.flaticon-plus-1:before {
  content: "\F120"; }

.flaticon-arrow:before {
  content: "\F121"; }

.flaticon-arrow-1:before {
  content: "\F122"; }

.flaticon-box:before {
  content: "\F123"; }

.flaticon-worlwide:before {
  content: "\F124"; }

.flaticon-square:before {
  content: "\F125"; }

.flaticon-gear:before {
  content: "\F126"; }

.flaticon-pin:before {
  content: "\F127"; }

.flaticon-subject:before {
  content: "\F128"; }

.flaticon-birth:before {
  content: "\F129"; }

.flaticon-phone:before {
  content: "\F12A"; }

.flaticon-mail:before {
  content: "\F12B"; }

.flaticon-linkedin:before {
  content: "\F12C"; }

.flaticon-cat:before {
  content: "\F12D"; }

.flaticon-paper:before {
  content: "\F12E"; }

.flaticon-multimedia:before {
  content: "\F12F"; }

.flaticon-folder:before {
  content: "\F130"; }

.flaticon-folder-1:before {
  content: "\F131"; }

.flaticon-calendar:before {
  content: "\F132"; }

.flaticon-edit-1:before {
  content: "\F133"; }

.flaticon-chat:before {
  content: "\F134"; }

.flaticon-upload:before {
  content: "\F135"; }

.flaticon-comment:before {
  content: "\F136"; }

.flaticon-work:before {
  content: "\F137"; }

.flaticon-user:before {
  content: "\F138"; }

.flaticon-exit:before {
  content: "\F139"; }

.flaticon-like:before {
  content: "\F13A"; }

.flaticon-plus-2:before {
  content: "\F13B"; }

.flaticon-portfolio:before {
  content: "\F13C"; }

.flaticon-eye:before {
  content: "\F13D"; }

.flaticon-search:before {
  content: "\F13E"; }

.flaticon-edit-2:before {
  content: "\F13F"; }

.flaticon-typography-1:before {
  content: "\F140"; }

.flaticon-icons8-uppercase-filled-50-1:before {
  content: "\F141"; }

.flaticon-icons8-lowercase-2-filled-50:before {
  content: "\F142"; }

.flaticon-sencase:before {
  content: "\F143"; }

.flaticon-ui:before {
  content: "\F144"; }

.flaticon-text-4:before {
  content: "\F145"; }

.flaticon-align-center:before {
  content: "\F146"; }

.flaticon-vertical-align:before {
  content: "\F147"; }

.flaticon-align:before {
  content: "\F148"; }

.flaticon-align-1:before {
  content: "\F149"; }

.flaticon-vertical-alignment:before {
  content: "\F14A"; }

.flaticon-vertical-alignment-1:before {
  content: "\F14B"; }

.flaticon-vertical-alignment-2:before {
  content: "\F14C"; }

.flaticon-document-1:before {
  content: "\F14D"; }

.flaticon-alert:before {
  content: "\F14E"; }

.flaticon-close:before {
  content: "\F14F"; }

html {
  font-size: 62.5%; }

body {
  font-size: 1.4rem;
  font-weight: 400;
  color: #000000;
  font-family: "Poppins", sans-serif; }

.body-font {
  font-family: "Roboto", sans-serif; }

.blink-loader {
  -webkit-animation: blinker 2s linear infinite;
          animation: blinker 2s linear infinite; }

@-webkit-keyframes blinker {
  50% {
    opacity: 0; } }

@keyframes blinker {
  50% {
    opacity: 0; } }

.r-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center; }
  .r-loader__cap {
    position: absolute;
    top: -16px;
    width: 55px;
    left: -13px;
    z-index: 1;
    -webkit-animation: toss-cap 1.1s linear infinite;
            animation: toss-cap 1.1s linear infinite; }
  .r-loader__reculta {
    width: 60px;
    -webkit-animation: toss-reculta 1.1s linear infinite;
            animation: toss-reculta 1.1s linear infinite; }

@-webkit-keyframes toss-cap {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: -16px; }
  10% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
    top: -21px; }
  35% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    top: -60px; }
  70% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    top: -16px; }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    top: -16px; } }

@keyframes toss-cap {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    top: -16px; }
  10% {
    -webkit-transform: rotate(-20deg);
            transform: rotate(-20deg);
    top: -21px; }
  35% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    top: -60px; }
  70% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    top: -16px; }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    top: -16px; } }

@-webkit-keyframes toss-reculta {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  5% {
    -webkit-transform: translateY(-5px) rotate(-20deg);
            transform: translateY(-5px) rotate(-20deg); }
  13% {
    -webkit-transform: translateY(-10px) rotate(0deg);
            transform: translateY(-10px) rotate(0deg); }
  20% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg); }
  100% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg); } }

@keyframes toss-reculta {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  5% {
    -webkit-transform: translateY(-5px) rotate(-20deg);
            transform: translateY(-5px) rotate(-20deg); }
  13% {
    -webkit-transform: translateY(-10px) rotate(0deg);
            transform: translateY(-10px) rotate(0deg); }
  20% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg); }
  100% {
    -webkit-transform: translateY(0) rotate(0deg);
            transform: translateY(0) rotate(0deg); } }

.r-error {
  border: 1px solid #ED3838;
  padding: 5px 10px;
  position: relative; }
  .r-error::after {
    content: "\2716";
    position: absolute;
    color: #ED3838;
    right: 5px;
    font-size: 0.8em;
    font-weight: 600;
    border: 1px solid #ED3838;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    text-align: center; }

.r-upload {
  display: inline-flex;
  border: 1px solid #d6d6d6;
  width: auto;
  padding: 3px 5px;
  border-radius: 3px;
  align-items: center;
  justify-content: center; }
  .r-upload__divider {
    background-color: #d6d6d6;
    width: 1px;
    height: 30px;
    margin: 0 10px; }
  .r-upload__newFile, .r-upload__browse {
    text-align: center;
    position: relative;
    overflow: hidden; }
  .r-upload__upload {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    text-indent: 100%; }
    .r-upload__upload:focus {
      outline: none; }

.fixed-sidebar {
  position: absolute;
  left: 15px;
  width: calc(100% - 30px); }

.heading-dropdown .r-dropdown {
  font-size: 2.8rem;
  border: none; }
  .heading-dropdown .r-dropdown__select {
    padding-right: 50px; }
    .heading-dropdown .r-dropdown__select__list {
      max-height: 250px;
      overflow: auto; }
  .heading-dropdown .r-dropdown i {
    font-size: inherit;
    color: inherit; }

.modal .modal-dialog .close {
  font-size: 2.2rem; }

.modal .modal-dialog.modal--delete, .modal .modal-dialog.modal--centered {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.modal .modal-dialog.modal--delete {
  max-width: 300px; }

.modal-md {
  max-width: 650px; }

.error-bar {
  position: fixed;
  z-index: 100000;
  background-color: #fecfc4;
  color: #fa3709;
  width: 100%;
  box-shadow: 0 0 4px 0px #707070;
  top: 0%;
  left: 0%; }

.success-bar {
  position: fixed;
  z-index: 100000;
  background-color: #d4fec4;
  color: #415857;
  width: 100%;
  box-shadow: 0 0 4px 0px #707070;
  top: 0%;
  left: 0%; }

.close-bn {
  font-size: 1.6rem;
  font-weight: 800;
  position: absolute;
  right: 10px;
  top: calc(50% - 15px); }

.r-tabs .nav-tabs .nav-link {
  cursor: pointer;
  border-radius: 10px 10px 0 0; }
  .r-tabs .nav-tabs .nav-link.active {
    color: #0066cc; }

.recruitment-drive {
  font-size: x-large;
  font-family: poppins;
  margin-top: 10px; }

@media screen and (max-width: 600px) {
  .recruitment-drive {
    font-size: 14px;
    margin-top: 0; } }

.logo-size {
  width: 165px; }

.outage {
  position: fixed;
  bottom: 0;
  width: 100%;
  margin: 0;
  text-align: center; }
  .outage .alert-info {
    color: #000000; }

.recharts-wrapper .recharts-legend-item svg {
  width: 10px !important; }

.r-btn {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  padding: 3px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;
  color: inherit; }
  .r-btn:focus {
    outline: 0; }
  .r-btn:disabled {
    cursor: not-allowed; }
  .r-btn--no-radius {
    border-radius: 0; }
  .r-btn--brandA {
    background-color: #35a2a2;
    color: #ffffff; }
  .r-btn--brandB {
    background-color: #ef0065;
    color: #ffffff; }
  .r-btn--brandI {
    background-color: #0066cc;
    color: #ffffff; }
  .r-btn--blue-3 {
    background-color: #0066cc;
    color: #ffffff; }
  .r-btn--dark {
    color: #ffffff;
    background-color: #000000;
    font-weight: 600; }
  .r-btn--dark-v {
    color: #ffffff;
    background-color: #252525;
    font-weight: 600;
    margin: 0;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid; }
  .r-btn--outline-dark-v {
    color: #000000;
    background-color: #ffffff;
    font-weight: 600;
    margin: 0;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid; }
  .r-btn--grey {
    background-color: #d6d6d6; }
  .r-btn--link {
    background-color: transparent;
    font-weight: 300;
    padding: 0; }
  .r-btn--link-blue {
    background-color: transparent;
    font-weight: 300;
    padding: 0;
    color: #0066cc; }
  .r-btn--blue {
    background-color: #cee2f5;
    color: #0066cc; }

.btn-profile-download {
  text-decoration: none !important; }

.r-heading-1 {
  font-size: 3.6rem;
  font-weight: 700; }

.r-heading-2 {
  font-size: 3.6rem;
  font-weight: 500; }

.r-heading-3 {
  font-size: 1.8rem;
  font-weight: 600; }

.r-heading-4 {
  font-size: 1.6rem;
  font-weight: 600; }

.r-heading-5 {
  font-size: 1.4rem;
  font-weight: 700; }

.r-heading-6 {
  font-size: 1.4rem;
  font-weight: 600; }

.r-heading-7 {
  font-size: 1.2rem;
  font-weight: 600; }

.r-subheading-1 {
  font-size: 2.4rem;
  font-weight: 500; }

.r-subheading-2 {
  font-size: 1.6rem;
  font-weight: 500; }

.r-subheading-3 {
  font-size: 1.4rem;
  font-weight: 500; }

.r-subheading-4 {
  font-size: 1.2rem;
  font-weight: 500; }

.r-inputfield {
  background-color: #f5f5f5;
  border: 0.5px solid #e5e5e5;
  font-weight: 300;
  padding: 5px;
  outline: none; }
  .r-inputfield:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both; }

.r-check {
  display: none; }
  .r-check + label {
    cursor: pointer;
    position: relative;
    padding-left: 15px; }
    .r-check + label::before {
      content: "";
      position: absolute;
      width: 11px;
      height: 11px;
      border: 1px solid #d6d6d6;
      border-radius: 2px;
      background-color: #ffffff;
      left: 0;
      top: 4px; }
  .r-check:checked + label::after {
    content: "";
    position: absolute;
    width: 7px;
    height: 3px;
    left: 2px;
    top: 7px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    border-style: solid;
    border-width: 0 0 1px 1px; }

.r-dropdown {
  border: 1px solid #d6d6d6;
  position: relative; }
  .r-dropdown select {
    background-color: #f5f5f5; }
  .r-dropdown__select {
    border: none;
    padding: 5px 22px 5px 5px;
    width: 100%;
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
    cursor: pointer;
    /* For IE10 */ }
    .r-dropdown__select::-ms-expand {
      display: none; }
    .r-dropdown__select__list {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      min-width: 100%;
      background-color: #ffffff;
      box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.09);
      padding: 0 12px;
      z-index: 1; }
      .r-dropdown__select__list ul li {
        padding: 5px 0;
        border-bottom: 1px solid #d6d6d6;
        cursor: pointer;
        white-space: nowrap; }
        .r-dropdown__select__list ul li:last-child {
          border: none; }
      .r-dropdown__select__list--show {
        display: block; }
  .r-dropdown i {
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: 1rem;
    color: #707070;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    pointer-events: none;
    z-index: 0; }
  .r-dropdown-2 .r-dropdown {
    border: none;
    display: inline-block; }
    .r-dropdown-2 .r-dropdown__select {
      background-color: transparent; }

.r-tag {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 2px 25px 2px 10px;
  margin-bottom: 10px;
  position: relative;
  font-family: "Poppins", sans-serif; }
  .r-tag a {
    color: inherit;
    text-decoration: none; }
  .r-tag__delete {
    cursor: pointer;
    outline: none;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    top: 5px;
    position: absolute;
    right: 10px;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
    .r-tag__delete:focus, .r-tag__delete:active {
      outline: none; }
  .r-tag--type-1 {
    border-radius: 6px;
    border: 1px solid #d6d6d6;
    font-size: 1.2rem; }
    .r-tag--type-1 .r-tag__delete {
      top: 3px; }
  .r-tag--no-delete {
    padding-right: 10px; }

.r-stars {
  color: #d6d6d6; }
  .r-stars--edit {
    padding: 5px 0; }
    .r-stars--edit > span {
      cursor: pointer; }
  .r-stars .fi.active {
    color: #ffcc33; }

.r-multifield {
  background-color: #f0f0f0;
  padding: 5px; }
  .r-multifield__list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap; }
    .r-multifield__list li {
      padding: 5px; }
      .r-multifield__list li .r-tag {
        background-color: #d6d6d6;
        word-break: break-word; }

.r-multiselect .dropdown-toggle {
  width: 100%;
  text-align: left;
  font-size: 1.2rem;
  position: relative; }
  .r-multiselect .dropdown-toggle::after {
    content: "\F121";
    font-family: "Flaticon";
    border: none;
    position: absolute;
    right: 10px;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    font-size: 1rem; }

.r-multiselect .dropdown-menu {
  max-height: 200px;
  overflow-x: hidden;
  padding: 0; }
  .r-multiselect .dropdown-menu li {
    white-space: nowrap;
    padding: 5px;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #d6d6d6; }
    .r-multiselect .dropdown-menu li label {
      margin: 0;
      padding-left: 3px; }

.r-toggle {
  font-size: 1.2rem;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  color: #0066cc; }
  .r-toggle__btn {
    display: inline-block;
    padding: 4px 14px;
    background-color: #0066cc;
    border-radius: 3px;
    border: 3px solid #0066cc;
    position: relative;
    cursor: pointer; }
    .r-toggle__btn::before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 60%;
      height: 100%;
      border-radius: 3px;
      background-color: #ffffff;
      transition: left 0.3s ease-in-out; }
  .r-toggle--selected-1 .r-toggle__value:last-child {
    opacity: 0.6; }
  .r-toggle--selected-2 .r-toggle__value:first-child {
    opacity: 0.6; }
  .r-toggle--selected-2 .r-toggle__btn::before {
    left: 40%; }

.r-btn-dropdown .dropdown-toggle {
  background-color: #f0f0f0;
  color: #000000;
  font-size: 1.4rem;
  font-weight: 500;
  border: none;
  padding: 0 10px; }
  .r-btn-dropdown .dropdown-toggle::after {
    content: "\F121";
    font-family: "flaticon";
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    vertical-align: middle;
    border: none;
    margin-left: 18px; }

.r-btn-dropdown .dropdown-menu {
  font-size: 1.4rem; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.r-rte .ql-snow .ql-color-picker .ql-picker-label,
.r-rte .ql-snow .ql-icon-picker .ql-picker-label {
  padding: 0px 4px; }

.r-rte .ql-toolbar.ql-snow .ql-formats {
  margin-right: 5px; }

.r-rte .ql-toolbar.ql-snow .ql-formats {
  margin-right: 0px; }

.r-rte * {
  font-family: "Poppins", sans-serif; }

.r-rte .ql-editor.ql-blank::before {
  font-style: normal; }

.r-rte .ql-container {
  background: #f5f5f5;
  border: 0.5px solid #e5e5e5; }

.r-rte .ql-editor {
  padding-left: 12px; }

.r-rte-toolbar-hide .ql-toolbar {
  height: 0;
  overflow: hidden;
  padding: 0;
  border: none;
  transition: all 0.3s ease-in-out; }

.r-rte-toolbar-hide:focus-within .ql-toolbar {
  height: 42px;
  padding: 8px;
  overflow: visible;
  border: 0.5px solid #e5e5e5; }

.r-rte .ql-letterspacing .ql-picker-label::before {
  content: "Spacing";
  color: #444;
  padding-right: 15px; }

.r-rte .ql-letterspacing .ql-picker-item:nth-child(1)::before {
  content: "Condensed"; }

.r-rte .ql-letterspacing .ql-picker-item:nth-child(2)::before {
  content: "Normal"; }

.r-rte .ql-letterspacing .ql-picker-item:nth-child(3)::before {
  content: "Expanded"; }

.r-richtext {
  white-space: pre-wrap; }
  .r-richtext p {
    margin: 0; }

.SingleDatePicker_picker {
  z-index: 9999999; }

.DateInput_input {
  background-color: #f0f0f0;
  border: none;
  font-weight: 300;
  font-size: 14px;
  padding: 5px;
  line-height: inherit; }

.DateRangePickerInput {
  display: flex; }
  .DateRangePickerInput .DateInput {
    width: 50%; }
    .DateRangePickerInput .DateInput_input {
      width: 100%; }
  .DateRangePickerInput_arrow {
    display: none; }
  .DateRangePickerInput .CalendarDay__default {
    color: inherit;
    border-color: #d6d6d6; }
  .DateRangePickerInput .CalendarDay__selected_span, .DateRangePickerInput .CalendarDay__hovered_span {
    background-color: #ffffff; }
  .DateRangePickerInput .CalendarDay__selected, .DateRangePickerInput .CalendarDay__hovered {
    color: #ffffff;
    background-color: #00a699; }

.DayPicker .CalendarMonth_caption {
  font-size: 12px;
  padding-bottom: 50px; }
  .DayPicker .CalendarMonth_caption strong {
    font-weight: 600; }

.DayPicker_weekHeader_li small {
  font-size: 12px;
  font-weight: 600; }

@-webkit-keyframes autofill {
  to {
    background: #f0f0f0; } }

.rc-time-picker-panel {
  z-index: 999999 !important; }

.ql-editor {
  height: 100% !important; }

.react-autosuggest__container {
  position: relative; }

.react-autosuggest__suggestions-container {
  position: absolute;
  left: 0;
  top: 30px;
  width: 100%;
  height: 0;
  overflow: hidden;
  opacity: 0;
  background-color: #ffffff;
  z-index: 2;
  border: 1px solid #d6d6d6;
  transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out; }
  .react-autosuggest__suggestions-container--open {
    height: 200px;
    overflow-y: auto;
    opacity: 1; }

.react-autosuggest__suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0; }

.react-autosuggest__suggestion {
  padding: 5px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer; }
  .react-autosuggest__suggestion--highlighted {
    background-color: #f0f0f0; }

.r-header {
  padding: 10px 0; }
  .r-header__logout {
    color: #ED3838; }

.r-nav {
  background-color: #ffffff;
  border-bottom: 0.5px solid #d6d6d6;
  box-shadow: 0px 0px 4px 1px #d6d6d6;
  z-index: 99999; }
  .r-nav__logo {
    width: 25px; }
  .r-nav__search {
    width: 100%;
    padding-right: 20px;
    font-size: 1.2rem;
    min-width: 250px; }
    .r-nav__search + span {
      position: absolute;
      top: 4px;
      right: 20px;
      color: #0c0c0c; }
  .r-nav__link {
    color: #000000;
    padding: 10px 24px;
    display: inline-block;
    transition: color 0.1s ease-in-out, background-color 0.3s ease-in-out; }
    .r-nav__link:hover {
      text-decoration: none;
      background-color: #ebf3fb; }
    .r-nav__link.active {
      text-decoration: none;
      color: #ffffff;
      background-color: #0066cc; }
  .r-nav__icon {
    width: 24px;
    padding: 8px 10px;
    border: 0;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
    position: relative;
    box-sizing: content-box; }
    .r-nav__icon .st0 {
      transition: opacity 0.3s ease-in-out; }
    .r-nav__icon path {
      transition: fill 0.3s ease-in-out; }
    .r-nav__icon svg {
      height: 20px; }
    .r-nav__icon--active, .r-nav__icon:hover {
      outline: none; }
      .r-nav__icon--active .st0, .r-nav__icon:hover .st0 {
        opacity: 0.8; }
      .r-nav__icon--active #icon__notification .st4, .r-nav__icon:hover #icon__notification .st4 {
        fill: #122a41; }
      .r-nav__icon--active #icon__conversations .st6, .r-nav__icon:hover #icon__conversations .st6 {
        fill: #122a41; }
      .r-nav__icon--active #icon__conversations .st7, .r-nav__icon:hover #icon__conversations .st7 {
        fill: #fff; }
      .r-nav__icon--active #icon__filemanager .st3, .r-nav__icon:hover #icon__filemanager .st3 {
        fill: #122a41; }
      .r-nav__icon--active #icon__calendar .st8, .r-nav__icon:hover #icon__calendar .st8 {
        fill: #0066cc; }
      .r-nav__icon--active #icon__calendar .st10, .r-nav__icon:hover #icon__calendar .st10 {
        fill: #fff; }
    .r-nav__icon__count {
      position: absolute;
      top: 8px;
      right: 0;
      background-color: #ED3838;
      border-radius: 50%;
      width: 17px;
      height: 15px;
      color: #ffffff;
      line-height: 1.4em;
      font-size: 0.7em;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center; }
  .r-nav__active {
    background-color: #d6d6d6; }
  .r-nav__dropdown {
    min-width: 280px;
    margin-left: -248px;
    z-index: 99999 !important; }
  .r-nav__dropdown_btn.btn-secondary:not(:disabled):not(.disabled), .r-nav__dropdown_btn.btn-secondary:not(:disabled):not(.disabled):hover, .r-nav__dropdown_btn.btn-secondary:not(:disabled):not(.disabled):focus, .r-nav__dropdown_btn.btn-secondary:not(:disabled):not(.disabled):active {
    background: none;
    border: none;
    color: #0066cc;
    font-size: 1.4rem;
    box-shadow: none; }

.notifications {
  width: 370px;
  font-family: "Poppins", sans-serif;
  max-width: none;
  border: none;
  box-shadow: 0 0 27px 2px rgba(0, 0, 0, 0.06);
  border-radius: 0;
  font-size: 1.2rem;
  font-weight: 300;
  max-height: 300px;
  overflow: auto; }
  .notifications .popover-body {
    padding: 0; }
  .notifications__header {
    font-weight: 500; }
  .notifications li {
    border-bottom: 1px solid #f0f0f0;
    padding: 5px 0;
    cursor: pointer; }
    .notifications li:last-child {
      border: none;
      margin-bottom: 0; }
    .notifications li:hover {
      background-color: #f5f5f5; }
    .notifications li.notifications--unread {
      background-color: #ebf3fb;
      color: #0066cc; }
      .notifications li.notifications--unread:hover {
        background-color: #cee2f5; }
    .notifications li__active {
      background-color: #d6d6d6; }
    .notifications li__dropdown {
      min-width: 280px;
      margin-left: -248px; }
    .notifications li__dropdown_btn, .notifications li__dropdown_btn:hover, .notifications li__dropdown_btn:focus {
      background: none;
      border: none;
      color: #000000;
      font-size: 1.4rem;
      box-shadow: none; }

.notifications {
  width: 370px;
  font-family: "Poppins", sans-serif;
  max-width: none;
  border: none;
  box-shadow: 0 0 27px 2px rgba(0, 0, 0, 0.06);
  border-radius: 0;
  font-size: 1.2rem;
  font-weight: 300;
  max-height: 300px;
  overflow: auto; }
  .notifications .popover-body {
    padding: 0; }
  .notifications__header {
    font-weight: 500; }
  .notifications li {
    border-bottom: 1px solid #f0f0f0;
    padding: 5px 0;
    cursor: pointer; }
    .notifications li:last-child {
      border: none;
      margin-bottom: 0; }
    .notifications li:hover {
      background-color: #f5f5f5; }
    .notifications li.notifications--unread {
      background-color: #ebf3fb;
      color: #0066cc; }
      .notifications li.notifications--unread:hover {
        background-color: #cee2f5; }

.r-sidenav {
  background-color: #ebf3fb;
  border-radius: 3px;
  color: #0066cc;
  position: absolute;
  border: 0.5px solid rgba(169, 175, 209, 0.19);
  width: calc(100% - 30px);
  left: 15px;
  overflow-x: hidden;
  max-height: calc(100vh - 100px);
  transition: all 250ms ease-in-out 0s; }
  .r-sidenav-fixed {
    position: fixed;
    width: 18%;
    transition: all 250ms ease-in-out 0s; }
  .r-sidenav-resume-fixed {
    position: fixed;
    width: 30px;
    left: 7.55%; }
  .r-sidenav__expended {
    width: 270px !important;
    z-index: 999;
    box-shadow: 3px 3px 15px 8px #aaa; }
  .r-sidenav__header {
    padding: 15px 10px;
    border-bottom: 1px solid #0066cc; }
  .r-sidenav__header .justify-content-between {
    flex-wrap: nowrap;
    white-space: nowrap; }
  .r-sidenav__body {
    box-shadow: inset 0 0 10px 0px #ccc;
    transition: left 3s; }
    .r-sidenav__body ul {
      transition: left 3s; }
  .r-sidenav__menu {
    list-style: none;
    padding-left: 0;
    margin: 0; }
    .r-sidenav__menu.resumes {
      max-height: calc(100vh - 200px);
      overflow: auto; }
    .r-sidenav__menu li {
      cursor: pointer;
      width: 100%;
      text-align: left;
      padding: 10px 20px;
      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
      border-radius: 0;
      flex-wrap: nowrap;
      word-break: break-word; }
      .r-sidenav__menu li:hover {
        background-color: #cee2f5; }
      .r-sidenav__menu li > span {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 1rem; }
      .r-sidenav__menu li.sidenav-active, .r-sidenav__menu li.sidenav-active:hover {
        background-color: #0066cc;
        color: #ebf3fb; }

.cv-builder {
  min-height: calc(100vh - 61px);
  background-color: #ffffff;
  margin-top: 20px; }
  .cv-builder__limit {
    position: absolute;
    border-top: 1px dashed #000000;
    width: calc(100% + 30px);
    top: 1127px;
    text-align: right;
    font-size: 1rem;
    font-weight: 600;
    pointer-events: none; }
  .cv-builder__doc {
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.05);
    font-family: "Times New Roman";
    width: 800px;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0; }
    .cv-builder__doc.spacing-xs .row {
      margin-left: -4px;
      margin-right: -4px; }
      .cv-builder__doc.spacing-xs .row > div {
        padding-left: 4px;
        padding-right: 4px; }
    .cv-builder__doc .borderLeft {
      border-left: 1px solid; }
    .cv-builder__doc .borderTop {
      border-top: 1px solid; }
    .cv-builder__doc .borderRight {
      border-right: 1px solid; }
    .cv-builder__doc .borderBottom {
      border-bottom: 1px solid; }
    .cv-builder__doc .footer {
      margin-top: auto; }
    .cv-builder__doc ul {
      list-style-type: circle;
      margin-bottom: 0; }
    .cv-builder__doc ol {
      list-style-type: lower-alpha;
      margin-bottom: 0; }
    .cv-builder__doc.bullet-hyphen .cv_point {
      list-style: none; }
      .cv-builder__doc.bullet-hyphen .cv_point > p:first-child {
        display: list-item;
        list-style: square; }
    .cv-builder__doc.bullet-hyphen ul {
      margin-bottom: 0;
      padding-left: 10px;
      list-style: none; }
      .cv-builder__doc.bullet-hyphen ul li {
        position: relative; }
        .cv-builder__doc.bullet-hyphen ul li::before {
          content: "-";
          position: absolute;
          left: -10px; }
    .cv-builder__doc.bullet-square .cv_point {
      list-style: none; }
      .cv-builder__doc.bullet-square .cv_point > p:first-child {
        display: list-item;
        list-style: square; }
    .cv-builder__doc.bullet-square ul {
      margin-bottom: 0;
      padding-left: 20px;
      list-style: square; }
    .cv-builder__doc.bullet-square-bordered p + ul {
      border-top: 1px solid #646464;
      margin-left: -4px;
      margin-right: -4px;
      padding-left: 24px;
      padding-right: 4px; }
    .cv-builder__doc.bullet-disc .cv_point {
      list-style: none; }
      .cv-builder__doc.bullet-disc .cv_point > p:first-child {
        display: list-item;
        list-style: disc; }
    .cv-builder__doc.bullet-disc ul {
      margin-bottom: 0;
      padding-left: 20px;
      list-style: disc; }
    .cv-builder__doc.bullet-disc-bordered p + ul {
      border-top: 1px solid #646464;
      margin-left: -4px;
      margin-right: -4px;
      padding-left: 24px;
      padding-right: 4px; }
    .cv-builder__doc.bullet-margin ul {
      -webkit-padding-start: 15px;
              padding-inline-start: 15px; }
    .cv-builder__doc p {
      margin: 0; }
    .cv-builder__doc [data-configpath] {
      transition: 0.3s box-shadow ease-in-out; }
      .cv-builder__doc [data-configpath]:hover, .cv-builder__doc [data-configpath]:focus, .cv-builder__doc [data-configpath]:active {
        outline: none; }
      .cv-builder__doc [data-configpath].highlight {
        box-shadow: 0 0 5px 1px inset #0066cc; }
    .cv-builder__doc__section {
      position: relative;
      transition: box-shadow 0.2s ease-in-out; }
    .cv-builder__doc__overlay {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.7);
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      cursor: pointer;
      color: #ffffff; }
      .cv-builder__doc__overlay:hover {
        opacity: 1; }
    .cv-builder__doc__tools {
      position: absolute;
      right: -8px;
      z-index: 1;
      background-color: rgba(240, 240, 240, 0.9);
      top: 0px;
      font-size: 1.6rem;
      border-radius: 5px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease-in-out;
      line-height: 1rem;
      padding: 0 5px;
      display: flex; }
      .cv-builder__doc__tools--left {
        left: -12px;
        right: auto; }
      .cv-builder__doc__tools button {
        padding: 3px 6px; }
      .cv-builder__doc__tools__reorder {
        display: flex;
        flex-direction: column; }
        .cv-builder__doc__tools__reorder button {
          font-size: 1rem;
          padding-top: 0;
          padding-bottom: 0; }
    .cv-builder__doc__hidden {
      background-color: #fecbbf;
      border-top: 3px solid #ef0065;
      position: relative;
      margin: 0 -15px;
      padding: 0 15px; }
      .cv-builder__doc__hidden.cv-builder__doc__section {
        border-top-width: 5px; }
        .cv-builder__doc__hidden.cv-builder__doc__section > .cv-builder__doc__tools {
          right: 20px; }
        .cv-builder__doc__hidden.cv-builder__doc__section span::after {
          border-top-width: 5px;
          top: -5px; }
        .cv-builder__doc__hidden.cv-builder__doc__section span::before {
          top: calc(100% - 5px); }
      .cv-builder__doc__hidden .hide-btn {
        display: none; }
      .cv-builder__doc__hidden span {
        cursor: pointer; }
        .cv-builder__doc__hidden span::after {
          background-color: #ef0065;
          height: 100%;
          content: "";
          position: absolute;
          right: -30px;
          border-top: 3px solid #ef0065;
          width: 30px;
          top: -3px; }
        .cv-builder__doc__hidden span::before {
          content: "";
          position: absolute;
          right: -30px;
          top: calc(100% - 3px);
          border-left: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-top: 10px solid #ef0065;
          border-right: 6px solid #ef0065;
          outline: none; }
    .cv-builder__doc .collapse {
      position: relative; }
  .cv-builder__toolbar {
    background-color: #ffffff;
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0px 0px 10px 0px #ccc; }
    .cv-builder__toolbar__accordian {
      cursor: pointer;
      padding: 10px 0 15px; }
      .cv-builder__toolbar__accordian i {
        float: right;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        transition: -webkit-transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
      .cv-builder__toolbar__accordian--collapsed i {
        -webkit-transform: rotate(-90deg);
                transform: rotate(-90deg); }
    .cv-builder__toolbar .btn-link {
      color: #000000;
      font-size: 1.5rem; }
      .cv-builder__toolbar .btn-link:hover, .cv-builder__toolbar .btn-link.active {
        color: #007bff; }

.footer-bottom-child > div:first-child {
  padding-bottom: 20px; }

.footer-bottom-child .footer {
  width: 100%;
  position: absolute;
  bottom: 0px; }

.cv-builder__doc__section > .cv-builder__doc__tools {
  right: 7px;
  top: -3px; }

.cv-builder__doc__section:hover > .cv-builder__doc__tools {
  opacity: 1;
  visibility: visible; }

.cv-builder__doc .editable .cv-builder__doc__overlay {
  visibility: hidden; }

.cv-builder__doc .editable .row:hover > .cv-builder__doc__tools, .cv-builder__doc .editable .cv-item:hover > .cv-builder__doc__tools {
  opacity: 1;
  visibility: visible; }

.cv__master .cv-builder__doc__tools {
  visibility: hidden !important; }

.cv__master .cv-builder__doc--unverified {
  background-color: rgba(255, 204, 51, 0.5) !important;
  display: flex !important; }

.cv__master .cv-builder__doc--rejected {
  background-color: rgba(250, 55, 9, 0.5) !important;
  display: flex !important; }

.single_page_cv {
  height: 1200px;
  overflow-y: clip; }
  .single_page_cv .cv-builder__limit {
    z-index: 999; }
    .single_page_cv .cv-builder__limit:after {
      content: "";
      height: 100vh;
      background: #ffffff91;
      position: absolute;
      width: 100%;
      left: 0;
      z-index: -1; }

.cv-builder__doc.only-verified .cv-builder__doc--unverified, .cv-builder__doc.only-verified .cv-builder__doc--rejected {
  display: none; }

.preview [data-configpath]:hover, .preview [data-configpath]:focus, .preview [data-configpath]:active, .preview [data-configpath].highlight {
  box-shadow: none;
  outline: none; }

.preview .cv-builder__doc__overlay {
  visibility: hidden; }

.preview .cv-builder__doc__tools {
  visibility: hidden !important; }

.preview .cv-builder__doc__hidden {
  display: none; }

.cvHeader, .cvBlocks {
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; }
  .cvHeader--hide, .cvBlocks--hide {
    background-color: #fecbbf; }
  .cvHeader > .collapse, .cvBlocks > .collapse {
    display: flex;
    height: 100%; }
    .cvHeader > .collapse > div, .cvBlocks > .collapse > div {
      align-self: center; }

.cv__pattern-1 > .collapse.show:nth-of-type(even) > .row {
  background-color: #f2f2f2; }

.list-to-vertical-columns ul {
  display: flex;
  padding-left: 0 !important; }

.list-to-vertical-columns li {
  flex: auto;
  line-height: 20px;
  display: inline-block;
  text-align: center;
  justify-content: center; }
  .list-to-vertical-columns li:not(:nth-last-of-type(1)) {
    border-right: 1px solid; }

.r-login {
  min-height: 100vh; }
  .r-login__logo {
    max-width: 100%;
    width: 100px;
    display: block; }
  .r-login__placeholder {
    background-color: #0066cc;
    background-image: url(/static/media/login.61ece80e.png);
    background-position: bottom;
    background-size: 100% auto;
    background-repeat: no-repeat; }
    .r-login__placeholder__text {
      font-size: 4.2rem;
      color: #ffffff;
      font-weight: 500;
      line-height: 1;
      padding-left: 10%;
      padding-top: 10%; }
      .r-login__placeholder__text p a {
        color: #ffffff;
        text-decoration: underline; }
  .r-login__links {
    display: flex;
    list-style: none;
    padding: 20px 0 0;
    font-weight: 500; }
    .r-login__links li {
      padding: 0 5px; }
      .r-login__links li::before {
        content: "|";
        padding-right: 5px; }
      .r-login__links li:first-child {
        padding-left: 0; }
        .r-login__links li:first-child::before {
          content: none; }
      .r-login__links li a {
        color: inherit; }

.feed {
  margin-top: 30px; }
  .feed__todo {
    list-style: none;
    background-color: #ebf3fb;
    padding: 8px 12px 8px 8px;
    margin-bottom: 40px;
    color: #0066cc; }
  .feed__event {
    padding: 0;
    list-style: none; }
    .feed__event li {
      border: 1px solid #d6d6d6;
      padding: 10px; }
  .feed__application {
    border: 1px solid #d6d6d6;
    padding: 5px;
    margin: 5px;
    border-radius: 4px; }

.post {
  /*box-shadow: 0px 0px 15px -1px $colorGrey3; */
  border: 1px solid #d6d6d6;
  border-radius: 10px;
  margin-bottom: 10px; }
  .post__header {
    display: flex;
    align-items: center; }
  .post__banner {
    border-color: #d6d6d6;
    border-style: solid;
    border-width: 1px 0;
    width: 100%; }
  .post__body {
    padding: 15px;
    word-break: break-word; }

.student__recruitment__tab {
  padding: 10px 0;
  min-height: 100vh; }

.student__nav {
  background-color: #363636;
  color: #ffffff;
  font-size: 1.2rem;
  font-weight: 400;
  padding: 15px 0; }
  .student__nav__item {
    text-align: center;
    cursor: pointer; }
    .student__nav__item::before {
      content: "|";
      position: absolute;
      bottom: 0;
      left: 0;
      font-weight: 300;
      font-size: 0.6em; }
    .student__nav__item:last-child::after {
      content: "|";
      position: absolute;
      bottom: 0;
      right: 0;
      font-weight: 300;
      font-size: 0.6em; }
  .student__nav__slider {
    height: 30px;
    line-height: 22px;
    background-color: #f5f5f5;
    border-radius: 15px;
    border: 2px solid #ffffff;
    box-shadow: 0 0 5px 2px inset rgba(0, 0, 0, 0.16);
    padding: 1px 0;
    color: #2e2c2c;
    opacity: 1; }
    .student__nav__slider-bar {
      background-color: #ffcc33;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAKElEQVQYV2NkwA58GbGI+zIwMGxGlwALghQjS8AFkSVQBGESGIIgCQBVnAVUPcxeHAAAAABJRU5ErkJggg==");
      background-size: 5px;
      background-repeat: repeat;
      height: 100%;
      margin: 0 3px;
      border-radius: 15px;
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.5); }
    .student__nav__slider-placeholder {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      cursor: pointer;
      display: flex;
      font-size: 1.4rem;
      font-weight: 600;
      justify-content: center;
      align-items: center; }
  .student__nav-toggle {
    background-color: #000000;
    height: 18px;
    position: relative; }
    .student__nav-toggle button {
      position: absolute;
      top: 50%;
      padding: 0;
      background-color: transparent;
      color: #d6d6d6;
      outline: none;
      cursor: pointer;
      border: none;
      left: 50%;
      font-weight: 300;
      font-size: 1.7rem;
      -webkit-transform: translate(-50%, -50%) rotate(-90deg);
              transform: translate(-50%, -50%) rotate(-90deg);
      transition: -webkit-transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
    .student__nav-toggle--collapsed button {
      -webkit-transform: translate(-50%, -50%) rotate(90deg);
              transform: translate(-50%, -50%) rotate(90deg); }

.student__profile--noedit .student__profile__image--edit, .student__profile--freeze .student__profile__image--edit {
  display: none; }

.student__profile--noedit.cv-point .student__profile__actions .student__profile__actions__btn, .student__profile--noedit.cv-point .student__profile__actions--add, .student__profile--noedit .student__profile__actions .student__profile__actions__btn, .student__profile--noedit .student__profile__actions--add, .student__profile--freeze.cv-point .student__profile__actions .student__profile__actions__btn, .student__profile--freeze.cv-point .student__profile__actions--add, .student__profile--freeze .student__profile__actions .student__profile__actions__btn, .student__profile--freeze .student__profile__actions--add {
  display: none; }

.student__profile--freeze .cv-point .student__profile__actions .student__profile__actions__btn, .student__profile--freeze .cv-point .student__profile__actions--add {
  display: inline; }

.student__profile__personal {
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 20px 15px; }

.student__profile__image {
  height: 100px;
  width: auto;
  border-radius: 3px; }
  .student__profile__image__container {
    position: relative; }
    .student__profile__image__container:hover .student__profile__image--edit {
      opacity: 1; }
  .student__profile__image--edit {
    position: absolute;
    right: 0;
    top: 0;
    padding: 1px 5px 4px 8px;
    border-radius: 0 0 0 20px;
    overflow: hidden;
    color: #0066cc;
    background-color: #cee2f5;
    font-size: 1rem;
    opacity: 0;
    transition: opacity 0.3s ease-in-out; }
  .student__profile__image--upload {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0;
    cursor: pointer; }

.student__profile__actions {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 1.2rem;
  line-height: 1.6rem; }
  .student__profile__actions__btn {
    opacity: 0;
    transition: opacity 0.3s ease-in-out; }
    .student__profile__actions__btn button {
      color: #0066cc;
      background-color: #cee2f5;
      margin: 0 3px;
      font-size: 1rem;
      padding: 1px 5px; }
  .student__profile__actions--add {
    white-space: nowrap; }
    .student__profile__actions--add::before {
      font-size: 0.9rem;
      position: absolute;
      left: 11px;
      top: 6px; }
    .student__profile__actions--add span {
      width: 0;
      transition: all 0.3s ease-in-out;
      display: inline-block;
      overflow: hidden;
      vertical-align: middle; }
    .student__profile__actions--add:hover span {
      width: 24px;
      margin-left: 10px; }

.student__profile__verification {
  padding: 0 5px;
  border-radius: 3px;
  font-weight: 500;
  text-transform: capitalize;
  width: 72px;
  display: inline-block; }
  .student__profile__verification--comment {
    width: 85px; }
    .student__profile__verification--comment:before {
      float: right;
      padding-top: 2px; }
  .student__profile__verification--approved {
    color: #14c424;
    background-color: #cbf2cf;
    border: 0.5px solid #8be293; }
  .student__profile__verification--rejected {
    color: #fa3709;
    background-color: #fecfc4;
    border: 0.5px solid #fd967d; }
  .student__profile__verification--pending {
    color: #fc3;
    background-color: #fff4d2;
    border: 0.5px solid #ffe698; }

.student__profile__container {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative; }
  .student__profile__container:last-child {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0; }
  .student__profile__container:first-child {
    padding-top: 0px; }
  .student__profile__container.r-card {
    padding: 20px 15px;
    border: 0.5px solid rgba(169, 175, 209, 0.19); }
  .student__profile__container--skill .student__profile__section {
    padding-top: 0; }

.student__profile__section {
  border-bottom: 1px solid #d6d6d6;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: relative;
  padding-top: 20px !important;
  word-break: break-word; }
  .student__profile__section:last-child {
    border: none;
    padding-bottom: 0;
    margin-bottom: 0; }
  .student__profile__section:first-child {
    padding-top: 0px; }
  .student__profile__section--placeholder {
    font-style: italic;
    opacity: 0.45; }
  .student__profile__section h2 {
    margin-bottom: 15px; }
  .student__profile__section .video-resume {
    position: relative; }
    .student__profile__section .video-resume i {
      background-color: #ffffff;
      color: #14c424;
      font-size: 1rem;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      display: inline-block;
      padding-left: 2px;
      padding-top: 1px;
      padding-bottom: 1px; }
    .student__profile__section .video-resume + button {
      color: #0066cc;
      background-color: #cee2f5;
      margin: 0 3px;
      font-size: 1rem;
      padding: 1px 5px;
      opacity: 0;
      transition: opacity 0.3s ease-in-out; }
    .student__profile__section .video-resume__container:hover button {
      opacity: 1; }
  .student__profile__section:hover > .student__profile__actions .student__profile__actions__btn {
    opacity: 1; }
  .student__profile__section > ul:hover ~ .student__profile__actions .student__profile__actions__btn {
    opacity: 0; }

.student__documents {
  width: calc(100% - 30px);
  max-height: calc(100vh - 80px);
  left: 100%; }
  .student__documents.r-card {
    box-shadow: none; }
  .student__documents__list {
    list-style: none;
    padding: 0px 5px 5px 10px;
    height: 312px;
    overflow-y: scroll;
    margin-top: -10px; }
    .student__documents__list li {
      display: flex;
      justify-content: space-between;
      padding: 10px 5px;
      cursor: pointer;
      transition: background-color 0.3s ease-in-out;
      align-items: center;
      border-bottom: 1px solid #d6d6d6; }
      .student__documents__list li:hover {
        background-color: #f5f5f5; }

.student__cv {
  position: fixed;
  right: 0;
  top: 50%; }
  .student__cv__preview {
    padding-top: 5%; }
    .student__cv__preview .r-btn {
      position: absolute;
      right: 0;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
    .student__cv__preview .modal-dialog {
      max-width: 800px; }
    .student__cv__preview .cv-builder__doc {
      height: 100%; }

.cv-point {
  padding: 20px 5px 5px;
  position: relative;
  font-family: "Roboto", sans-serif;
  font-weight: 300; }
  .cv-point select {
    font-weight: 300; }
  .cv-point .cv_header_input {
    font-weight: 400;
    padding-left: 12px;
    height: 100%; }
  .cv-point--add {
    background-color: #f0f0f0;
    border-radius: 3px;
    cursor: pointer;
    padding: 5px; }
  .cv-point__upload {
    border: 1px solid #d6d6d6;
    padding: 0 5px;
    margin-left: 5px;
    background-color: #ffffff; }
  .cv-point:hover .student__profile__actions__btn {
    opacity: 1; }

.resumes li {
  position: relative;
  cursor: default; }

.resumes__star {
  color: #ffcc33;
  position: absolute;
  right: 10px;
  top: 50%;
  font-size: 2rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%); }

.apply--no-edit .apply__application__apply {
  display: none; }

.apply__sortby .r-dropdown {
  border: none; }

.apply__tab--selected {
  border-bottom: 2px solid #000000; }

.apply__multirole-info {
  width: 18px;
  position: absolute;
  margin-left: 10px;
  cursor: pointer; }

.apply__jobs__header {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 2px;
  border-top: 1px solid #d6d6d6; }

.apply__jobs__company {
  border-top: 2px solid #f0f0f0; }
  .apply__jobs__company:first-child {
    border: none; }
  .apply__jobs__company.apply__jobs__multirole {
    border: none; }

.apply__jobs__status {
  background-color: #007bff;
  color: #ffffff;
  font-size: 1.2rem;
  padding: 5px 10px;
  border-radius: 7px;
  box-shadow: 0 0 10px 3px #ccc;
  cursor: pointer;
  transition: -webkit-transform 100ms;
  transition: transform 100ms;
  transition: transform 100ms, -webkit-transform 100ms; }
  .apply__jobs__status:hover {
    -webkit-transform: scale(1.04);
            transform: scale(1.04); }
  .apply__jobs__status:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }

.apply__jobs__profile {
  margin-top: 10px;
  margin-bottom: 10px; }

.apply__application {
  background-color: #cee2f5;
  padding: 30px;
  left: 0;
  width: 100%; }
  .apply__application__step {
    display: flex;
    flex-direction: column;
    border-left: 1px solid;
    padding-left: 25px;
    margin-left: 15px;
    height: 100%; }
    .apply__application__step::before {
      content: "\25A0";
      position: absolute;
      top: -5px;
      left: 24px;
      font-size: 24px; }
    .apply__application__step-next {
      flex: 1 1 100%;
      position: relative;
      width: 2px;
      background: #000000; }
      .apply__application__step-next::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        left: -2px;
        top: 0;
        background: #000000; }
  .apply__application__tab {
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0;
    border-bottom: 2px solid transparent;
    border-radius: 0; }
    .apply__application__tab.active {
      border-color: #000000; }
  .apply__application__resume {
    background-color: rgba(112, 112, 112, 0.6);
    color: #ffffff;
    padding: 5px;
    text-align: center;
    font-size: 1.2rem;
    height: 100%;
    cursor: pointer;
    word-break: break-word; }
    .apply__application__resume.active {
      background-color: rgba(53, 162, 162, 0.6); }

.apply__performance {
  display: flex;
  font-size: 1.2rem;
  align-items: flex-end; }
  .apply__performance__step {
    flex: 0 0 14.28%; }
    .apply__performance__step:last-child .apply__performance__track::after {
      content: none; }
    .apply__performance__step--done .apply__performance__track button {
      background-color: #ffcc33;
      border-color: #ffcc33; }
  .apply__performance__track {
    display: flex;
    align-items: center; }
    .apply__performance__track button {
      flex: 0 0 5px;
      background: #f5f5f5;
      padding: 3px;
      border: 1px solid #d6d6d6;
      border-radius: 10px; }
    .apply__performance__track::after {
      content: "";
      flex: 1 1 100%;
      height: 2px;
      background-color: #d6d6d6; }

.recruitment_nav_collapse {
  width: 100%;
  top: 42px;
  box-shadow: 0 2px 10px 2px #d6d6d6; }

.recruitment_nav_button {
  height: 200px; }

.verifier {
  position: absolute; }
  .verifier__container {
    width: 100%; }
  .verifier__main {
    color: #000000;
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: "Poppins";
    padding-top: 0px;
    padding-bottom: 10px; }
  .verifier__verify-statement__main {
    height: 100px;
    background-color: #339999;
    color: #ffffff;
    width: 100%;
    margin: 0px;
    padding: 35px; }
  .verifier__verify-count-button {
    margin-top: 20px;
    padding-top: 10px;
    margin-right: 0;
    padding-bottom: 0px;
    margin-top: 0;
    font-size: 50px;
    line-height: 20px;
    color: #0066cc;
    font-family: "Poppins";
    font-weight: 600;
    background-color: transparent;
    border: none; }
    .verifier__verify-count-button:focus {
      outline: none; }
  .verifier__verify-text-button {
    margin-top: 0;
    padding-top: 0;
    background-color: transparent;
    color: black;
    border: none;
    cursor: pointer; }
    .verifier__verify-text-button.active {
      border-bottom: 4px solid #282828;
      border-radius: 3px;
      outline: none;
      box-shadow: none; }
  .verifier__card-link:hover {
    text-decoration: none; }
  .verifier__verify-summary-card {
    cursor: pointer;
    margin-top: 30px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 30px;
    width: 345px;
    height: auto;
    padding-top: 5px;
    padding-bottom: 0;
    border-radius: 3px;
    background-color: #ffffff;
    border: 1px solid #d6d6d6;
    color: #1f1d1d;
    overflow: hidden;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
    .verifier__verify-summary-card:hover {
      box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.17);
      -webkit-transform: scale(1.02);
              transform: scale(1.02); }
  .verifier__verify-card-button {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 0;
    padding-bottom: 0;
    padding: 8px;
    background-color: #f5f5f5; }
  .verifier__list {
    width: 100%;
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 5px;
    background-color: #ebebeb; }
  .verifier__detail-scroll {
    height: 80%; }
  .verifier__detail-page {
    margin: 0px;
    margin-top: 40px;
    width: 250px;
    border: 1px solid  #c9c2c2;
    padding: 0px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between; }
  .verifier__swipe-button {
    background-color: transparent;
    color: #383535;
    border: none;
    height: 100%; }
    .verifier__swipe-button:hover {
      background-color: #eae8e8;
      border: none;
      color: #1f1d1d; }
    .verifier__swipe-button:focus {
      box-shadow: none; }
  .verifier__student-name {
    width: auto;
    margin-left: 0px;
    margin-right: 0px;
    padding: 5px;
    letter-spacing: 2px;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 15px; }
  .verifier__timer {
    margin-top: 40px;
    margin-left: 40px; }
  .verifier__resume-button {
    margin-top: 40px;
    margin-left: 40px;
    border-radius: 3px;
    text-align: center; }
    .verifier__resume-button:focus {
      box-shadow: none;
      outline: none; }
    .verifier__resume-button:hover {
      background-color: #339999; }
  .verifier__section-card {
    margin-top: 10px;
    margin-bottom: 40px; }
  .verifier__card-body.active {
    background-color: #ebf3fb; }
  .verifier__section-card-button {
    margin: 10px;
    width: auto;
    padding: 3px;
    padding-left: 7px;
    padding-right: 7px;
    background-color: #fff;
    color: #383535;
    border: 1px solid #c9c2c2;
    border-radius: 3px; }
    .verifier__section-card-button:hover {
      background-color: #eae8e8;
      color: #383535;
      border: 1px solid #c9c2c2;
      border-radius: 3px; }
    .verifier__section-card-button:focus {
      box-shadow: none;
      outline: none; }
  .verifier__section-card-button-selected {
    background-color: #aae3b0;
    color: #339999; }
    .verifier__section-card-button-selected:hover {
      background-color: #aae3b0;
      color: #339999; }
  .verifier__status-button-brief {
    margin: 0px;
    height: 30px;
    margin-top: 50px;
    margin-bottom: 10px;
    border-radius: 3px;
    letter-spacing: 2px;
    padding: 3px;
    padding-bottom: 10px;
    border: none;
    background-color: transparent;
    color: #1f1d1d; }
    .verifier__status-button-brief:hover {
      border: none;
      background-color: transparent;
      color: #1f1d1d; }
    .verifier__status-button-brief.active {
      border: none;
      background-color: transparent;
      color: #1f1d1d;
      border-bottom: 3px solid #1f1d1d; }
    .verifier__status-button-brief:focus {
      box-shadow: none;
      outline: none; }
  .verifier__font-size {
    font-size: 1.5em;
    font-family: "Poppins";
    font-weight: 500; }
  .verifier__student-proof {
    max-height: calc(100vh - 82px);
    position: fixed;
    top: 82px;
    overflow: auto;
    overflow-x: hidden; }
    .verifier__student-proof .carousel-control-next-icon {
      background-color: black;
      width: 50%;
      height: 50%; }
    .verifier__student-proof .carousel-control-prev-icon {
      background-color: black;
      width: 50%;
      height: 50%; }
    .verifier__student-proof .carousel-control-next {
      right: -12px;
      height: 50%;
      width: 60px;
      bottom: 25%;
      top: 25%; }
    .verifier__student-proof .carousel-control-prev {
      left: -12px;
      height: 50%;
      width: 60px;
      bottom: 25%;
      top: 25%; }
  .verifier__student-proof-ex {
    width: 50%;
    margin: 4rem; }
  .verifier__comment-box {
    border-radius: 5px;
    box-shadow: 1px 1px 10px 15px rgba(0, 6, 204, 0.16); }
  .verifier__send-button {
    background-color: transparent;
    color: #0006cc;
    border: none; }
    .verifier__send-button .active {
      outline: none; }
    .verifier__send-button .focus {
      outline: none; }
  .verifier__comment-body {
    height: 200px;
    overflow-x: hidden; }
  .verifier__details-page-back-button {
    margin-top: 38px; }
  .verifier__search-bar {
    margin-left: -30px;
    position: absolute; }
  .verifier__page .pagination {
    float: right; }

.talk-bubble {
  margin-top: 5px;
  margin-bottom: 2px;
  display: inline-block;
  position: relative;
  width: 70%;
  height: auto;
  background-color: #f5f5f5;
  border-radius: 5px solid #d6d6d6; }

.tri-right.border.left-top:before {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: -40px;
  right: auto;
  top: -20px;
  bottom: auto;
  border: 32px solid;
  border-color: #666; }

.tri-right.left-top:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: -7px;
  right: auto;
  top: 0px;
  bottom: auto;
  border: 7px solid;
  border-color: #f5f5f5  transparent transparent; }

.tri-right.right-top:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: -7px;
  top: 0px;
  bottom: auto;
  border: 7px solid;
  border-color: #f5f5f5  transparent transparent; }

.talktext {
  text-align: left;
  line-height: auto; }

.talktext p {
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em; }

.paginationItemStyle.page-item.active .page-link {
  z-index: 1;
  color: #000;
  background-color: grey;
  border-color: grey; }

.border__t {
  border-top: 1px solid #d6d6d6;
  padding-top: 15px;
  margin-top: 40px;
  position: relative;
  padding-bottom: 5px; }

.spocModel1 .modal-body {
  min-height: 200px; }

.active-tab {
  border-bottom: 2px solid; }
  .active-tab-brandB {
    border-color: #ef0065; }

.location_icon {
  position: absolute;
  margin-left: -25px !important;
  background: black;
  color: white;
  border-radius: 0;
  width: 25px;
  height: 31px; }

.child-boundry-campus-left {
  position: relative; }
  .child-boundry-campus-left:before {
    content: "";
    height: calc(100% - 85px);
    margin-left: 10px;
    border-left: 1px solid;
    border-left-style: dotted;
    position: absolute;
    margin-top: 35px; }

.child-boundry-campus {
  position: relative; }
  .child-boundry-campus:after {
    vertical-align: middle;
    content: "";
    width: 80%;
    margin-left: 10px;
    border-top: 1px solid;
    border-top-style: dotted;
    position: absolute; }

.child-boundry-course {
  position: relative; }
  .child-boundry-course:after {
    content: "";
    width: 45%;
    margin-left: 48%;
    border-top: 1px solid;
    border-top-style: dotted;
    position: absolute; }

.border-left-white {
  border-left: 2px solid #ffffff !important; }

.child-boundry-course-left {
  position: relative; }
  .child-boundry-course-left:before {
    content: "";
    height: calc(100% - 40px);
    margin-left: 75px;
    border-left: 1px solid;
    border-left-style: dotted;
    position: absolute;
    margin-top: 25px; }

.permission-btn {
  display: none; }

.permission:hover .permission-btn {
  display: block; }

.company-name {
  border: 0px solid #d6d6d6;
  border-style: solid;
  border-color: transparent;
  border-width: 1px 0;
  transition: border 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, border 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, border 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out; }
  .company-name:focus {
    position: relative; }

.company-name:hover {
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6; }

.add_process_company_btn {
  border-style: dashed !important;
  border-color: #6a7b7b !important;
  font-weight: 800;
  color: #94a7b9;
  border-radius: 6px;
  font-style: italic;
  background-color: #eaf3f3; }

.company-delete {
  display: none; }

.company-name:hover .company-delete {
  display: block; }

.processes .add-count {
  font-weight: 400;
  padding: 0 8px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-direction: row; }
  .processes .add-count i:last-child {
    font-size: 1rem; }

.processes__company {
  margin-top: 8px;
  padding: 10px 0; }
  .processes__company > div {
    padding: 0 5px !important; }
  .processes__company__roles {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #ffcc33; }
    .processes__company__roles::before {
      content: "";
      width: 2px;
      top: calc(-50% - 5px);
      left: calc(50% - 1px);
      height: calc(100% + 5px);
      z-index: -1;
      position: absolute;
      background: #d6d6d6; }
    .processes__company__roles::after {
      content: "";
      top: calc(50% - 1px);
      height: 2px;
      width: 50%;
      z-index: -1;
      position: absolute;
      background: #d6d6d6; }

.processes__meeting-status {
  background-color: #6600cc;
  padding: 10px 10px;
  border-radius: 7px;
  transition: -webkit-transform 100ms;
  transition: transform 100ms;
  transition: transform 100ms, -webkit-transform 100ms;
  cursor: pointer;
  position: relative; }
  .processes__meeting-status:hover {
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
    z-index: 1; }
  .processes__meeting-status:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
    z-index: 1; }
  .processes__meeting-status__contact {
    background-color: #ffffff;
    border: none;
    font-size: 1.2rem;
    font-weight: 500;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px; }
    .processes__meeting-status__contact .r-dropdown__select {
      padding-top: 0;
      padding-bottom: 0; }
    .processes__meeting-status__contact i {
      top: 2px; }
  .processes__meeting-status__date {
    background-color: #d4b8f1;
    font-size: 1.2rem;
    text-align: center;
    padding: 0 2px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px; }

.processes__application {
  background-color: #0066cc;
  padding: 10px 10px;
  border-radius: 7px;
  transition: -webkit-transform 100ms;
  transition: transform 100ms;
  transition: transform 100ms, -webkit-transform 100ms;
  margin-top: 10px;
  cursor: pointer; }
  .processes__application:first-child {
    margin-top: 0; }
  .processes__application:hover {
    -webkit-transform: scale(1.04);
            transform: scale(1.04); }
  .processes__application:active {
    -webkit-transform: scale(0.95);
            transform: scale(0.95); }
  .processes__application__role {
    background-color: #ffffff;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 5px;
    font-weight: 600; }
  .processes__application__status {
    margin: 0 5px;
    display: inline-flex;
    align-items: center; }
    .processes__application__status::after {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      border: 1px solid;
      border-radius: 4px;
      margin-left: 2px; }
    .processes__application__status--1::after {
      background-color: #aae3b0;
      border-color: #aae3b0; }
    .processes__application__status--2::after {
      background-color: #ffffff;
      border-color: #aae3b0; }
    .processes__application__status--3::after {
      background-color: #ffffff;
      border-color: #14c424; }
    .processes__application__status--4::after {
      background-color: #ffffff;
      border-color: #fa3709; }
  .processes__application__create {
    margin-top: 10px;
    height: 38px;
    width: 100%;
    background-color: #f5f5f5;
    color: #0066cc;
    font-weight: 600;
    border: 1px solid #d6d6d6; }
    .processes__application__create:first-child {
      margin-top: 0; }
  .processes__application__detail {
    background-color: #ebf3fb;
    padding: 30px 15px 15px; }
  .processes__application__post {
    background-color: #ffffff;
    margin: 10px 0; }
  .processes__application__company {
    display: flex; }
    .processes__application__company__contact {
      flex: 1 1 50%;
      background-color: #d6d6d6;
      color: #ffffff;
      padding: 5px;
      margin-bottom: 10px;
      cursor: pointer; }
      .processes__application__company__contact:nth-child(odd) {
        margin-right: 5px; }
      .processes__application__company__contact:nth-child(even) {
        margin-left: 5px; }
      .processes__application__company__contact:hover, .processes__application__company__contact.active {
        background-color: #35a2a2; }

.processes__milestone {
  background-color: #0066cc;
  padding: 10px;
  display: flex;
  flex-direction: column; }
  .processes__milestone__share {
    font-size: 1.1rem;
    font-weight: 600;
    position: absolute;
    top: 0;
    cursor: pointer;
    display: flex;
    left: 50%;
    white-space: nowrap;
    background-color: #0066cc;
    color: #ffffff;
    z-index: 1;
    align-items: center;
    border-radius: 0 0 4px 4px;
    padding: 2px 5px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    display: none; }
    .processes__milestone__share--disabled {
      pointer-events: none; }
    .processes__milestone__share img {
      width: 10px;
      margin-right: 2px; }
  .processes__milestone__item {
    position: relative;
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 3px;
    padding: 5px;
    margin-top: 10px;
    transition: background-color 0.2s ease-in-out; }
    .processes__milestone__item:first-child {
      padding: 5px 0 0; }
    .processes__milestone__item:hover {
      background-color: #ffffff; }
      .processes__milestone__item:hover .processes__milestone__share {
        display: block; }
    .processes__milestone__item h4 {
      line-height: 1; }
    .processes__milestone__item .r-btn {
      padding: 0 5px;
      font-size: 1.2rem;
      background-color: #a9afd1;
      border-radius: 3px; }
      .processes__milestone__item .r-btn.add-btn {
        font-size: 1.6rem;
        background-color: transparent; }
  .processes__milestone__desc {
    padding: 5px;
    background-color: #cee2f5;
    width: 100%;
    border: none;
    outline: none;
    text-align: left;
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 1px; }
    .processes__milestone__desc:focus {
      outline: none; }

.processes__chatbox {
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px -3px #d6d6d6; }
  .processes__chatbox__messages {
    flex: 1 1 90%;
    background-color: #ffffff; }
  .processes__chatbox__actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px 2px;
    background-color: #ffffff; }
    .processes__chatbox__actions label {
      margin-bottom: 0; }

.processes__table__1 {
  font-size: 1.2rem;
  margin-top: 20px;
  border-bottom: 1px solid #707070; }
  .processes__table__1 tr {
    border-top: 1px solid #707070;
    background-color: #f0f0f0; }
    .processes__table__1 tr td {
      border: none; }
    .processes__table__1 tr.disabled-row {
      opacity: 0.5; }

.processes__table__2 thead tr {
  background-color: #d6d6d6; }

.processes__table__2 tbody tr {
  border-bottom: 1px solid #707070; }
  .processes__table__2 tbody tr td {
    border: none; }

.processes__meeting {
  background-color: #f0f0f0;
  padding: 30px 15px 15px; }
  .processes__meeting__contact {
    background-color: #464646;
    padding: 5px;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 300;
    margin-top: 10px;
    border-radius: 3px; }
  .processes__meeting__profile {
    text-align: center;
    padding: 8px;
    background-color: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 0px 5px 1px #d6d6d6;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around; }
    .processes__meeting__profile span {
      display: block;
      background-color: #ffcc33;
      border-radius: 5px;
      padding: 5px;
      font-size: 1.2rem;
      font-weight: 500;
      position: relative; }
      .processes__meeting__profile span button {
        position: absolute;
        right: 0;
        top: 2px; }

.processes__verifiers {
  padding-left: 0;
  list-style: none;
  margin-top: 20px; }
  .processes__verifiers li {
    background-color: #ffffff;
    padding: 10px;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer; }
    .processes__verifiers li.active, .processes__verifiers li:hover {
      background-color: #f5f5f5; }

.processes__students {
  padding-left: 0;
  list-style: none;
  margin-top: 20px; }
  .processes__students li {
    background-color: #ffffff;
    padding: 5px 10px;
    border-top: 1px solid #d6d6d6; }

.modal__post__header {
  font-size: 1.4rem;
  font-weight: 600;
  border-bottom: 3px solid transparent;
  padding: 0 10px;
  display: inline-block; }
  .modal__post__header.active {
    border-color: #ef0065; }

.modal__post__attachment {
  background-color: #f0f0f0;
  display: flex;
  min-height: 120px;
  align-items: center;
  justify-content: center;
  margin: 0 -10px; }
  .modal__post__attachment span {
    font-size: 4rem;
    margin-left: 12px;
    color: #2e2c2c; }

.modal__post__fields .r-inputfield {
  width: 100%;
  background-color: transparent;
  border-width: 0 0 1px; }

.modal__post__fields .r-rte .ql-container {
  background-color: transparent; }

.modal__title {
  border-bottom: 2px solid #000000; }

.company-card {
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  transition: all 0.3s; }
  .company-card-selected {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    border: 1px thin #06c;
    border-radius: 4px;
    box-shadow: 0 0 6px 0px #ebf3fb;
    background-color: #ebf3fb; }

.company_meeting_status .processes__meeting-status {
  -webkit-transform: scale(1);
          transform: scale(1);
  pointer-events: none; }
  .company_meeting_status .processes__meeting-status .flaticon-arrow {
    display: none; }

.contact-card {
  height: 100%; }
  .contact-card-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0; }
  .contact-card-body {
    min-height: 200px;
    height: 100%;
    position: relative;
    padding-bottom: 40px; }
  .contact-card-footer {
    position: absolute;
    bottom: 0; }
  .contact-card .student__profile__actions__btn {
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }
  .contact-card:hover .student__profile__actions__btn {
    opacity: 1; }

.green-selected {
  color: #03ab13; }

.multiple_role {
  margin-top: -5px; }

.multipleRoleBar::before {
  content: " ";
  height: 30px;
  width: 20px;
  background: #ff0000;
  position: absolute;
  left: 50%;
  top: -20px;
  z-index: -1; }

.date_font {
  font-size: 11px; }

.switch {
  position: relative;
  width: 3rem;
  height: 1.4rem;
  border: 1px solid #2e2c2c;
  border-radius: 12px;
  transition: background-color 100ms ease-out;
  z-index: 1; }

.toggle-button {
  /*position: absolute;*/
  width: 1.2rem;
  height: 1.2rem;
  /*background-color: $toggleColor;*/
  border-radius: 50%;
  transition: -webkit-transform 100ms ease-in-out;
  transition: transform 100ms ease-in-out;
  transition: transform 100ms ease-in-out, -webkit-transform 100ms ease-in-out;
  z-index: 3;
  /*border: 0.05rem solid $toggleColor;*/
  top: -0.05rem; }

.switch_is-off {
  background-color: #fed3c9;
  box-shadow: inset 0px 0px 2px 1px rgba(0, 0, 0, 0.13); }

.switch_is-on {
  background-color: #bee8c2;
  box-shadow: inset 0px 0px 2px 1px rgba(0, 0, 0, 0.13); }

.toggle-button_position-left {
  -webkit-transform: translateX(-0.1rem);
          transform: translateX(-0.1rem);
  margin-left: 1px;
  padding: 1px;
  background-color: #fa3709; }

.toggle-button_position-right {
  -webkit-transform: translateX(1.6rem);
          transform: translateX(1.6rem);
  margin-right: 1.5px;
  padding: 1px;
  background-color: #03ab13; }

.r-shadow-1 {
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.16);
  transition: box-shadow 0.3s ease-in-out; }
  .r-shadow-1:hover {
    box-shadow: 1px 1px 35px rgba(0, 0, 0, 0.16); }

.r-shadow-1 {
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.16);
  transition: box-shadow 0.3s ease-in-out; }
  .r-shadow-1:hover {
    box-shadow: 1px 1px 35px rgba(0, 0, 0, 0.25); }

.r-bg--brandA {
  background-color: #35a2a2; }

.r-bg--brandB {
  background-color: #ef0065; }

.r-bg--brandC {
  background-color: #012060; }

.r-bg--brandD {
  background-color: #14c424; }

.r-bg--brandE {
  background-color: #ffcc33; }

.r-bg--brandF {
  background-color: #fa3709; }

.r-bg--brandG {
  background-color: #339999; }

.r-bg--brandH {
  background-color: #aae3b0; }

.r-bg--brandI {
  background-color: #e81f1f; }

.r-bg--brandJ {
  background-color: #21a533; }

.r-bg--grey {
  background-color: #d6d6d6; }

.r-bg--yellow {
  background-color: #f4d742; }

.r-bg--skylight {
  background-color: #4dd2ea; }

.r-bg--grey-1 {
  background-color: #f5f5f5; }

.r-bg--grey-2 {
  background-color: #f0f0f0; }

.r-bg--grey-3 {
  background-color: #d6d6d6; }

.r-bg--grey-4 {
  background-color: #707070; }

.r-bg--grey-5 {
  background-color: #2e2c2c; }

.r-bg--light {
  background-color: #ffffff; }

.r-bg--blue-2 {
  background-color: #cee2f5; }

.r-bg--blue-1 {
  background-color: #ebf3fb; }

.r-bg--dark {
  background-color: #000000; }

.r-bg--blue-3 {
  background-color: #0066cc; }

.r-bg--hightlight-error {
  background-color: #fecbbf; }

.r-bg--transparent {
  background-color: transparent; }

.r-text--red-1 {
  color: #FF2A2A; }

.r-text--skyblue {
  color: #42e2f4; }

.r-text--green-1 {
  color: #12C935; }

.r-text--grey {
  color: #2e2c2c; }

.r-text--grey-1 {
  color: #f5f5f5; }

.r-text--grey-2 {
  color: #f0f0f0; }

.r-text--grey-3 {
  color: #d6d6d6; }

.r-text--grey-4 {
  color: #707070; }

.r-text--grey-5 {
  color: #2e2c2c; }

.r-text--grey-6 {
  color: #363636; }

.r-text--grey-7 {
  color: #0c0c0c; }

.r-text--grey-8 {
  color: #464646; }

.r-text--light {
  color: #ffffff; }

.r-text--dark {
  color: #000000; }

.r-text--blue-3 {
  color: #0066cc; }

.r-text--blue-4 {
  color: #339999; }

.r-text--brandA {
  color: #35a2a2; }

.r-text--brandB {
  color: #ef0065; }

.r-text--brandC {
  color: #012060; }

.r-text--brandD {
  color: #14c424; }

.r-text--brandE {
  color: #ffcc33; }

.r-text--brandF {
  color: #fa3709; }

.r-text--brandG {
  color: #339999; }

.r-text--brandI {
  color: #0066cc; }

.r-text--sm {
  font-size: 1.2rem; }

.r-text--md {
  font-size: 1.4rem; }

.r-text--lg {
  font-size: 1.6rem; }

.r-transition--all {
  transition: all 0.3s ease-in-out; }

.r-transition--margin {
  transition: margin 0.3s ease-in-out; }

.r-progress {
  border: 1px solid #d6d6d6;
  height: 16px;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 1px; }
  .r-progress .progress-bar {
    border-radius: 6px; }

.r-card {
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  padding: 20px 15px;
  border: 0.5px solid rgba(169, 175, 209, 0.19); }

.r-pointer {
  cursor: pointer; }

.fi-abs {
  padding-left: 20px;
  position: relative; }
  .fi-abs::before {
    position: absolute;
    left: 0;
    line-height: inherit; }

.rotate--90 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.rotate--45 {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.r-radius--1 {
  border-radius: 3px; }

.r-radius--2 {
  border-radius: 6px; }

.r-radius--3 {
  border-radius: 9px; }

.flaticon-arrow-q:before {
  content: "\F121";
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg); }

.flaticon-arrow-up:before {
  content: "\F121";
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg); }

.flaticon-arrow-side:before {
  content: "\F121";
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.flaticon-arrow-side-2:before {
  content: "\F121";
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg); }

.flaticon-plus-3:before {
  content: "\F120";
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.selectable {
  cursor: pointer; }

.input-icon {
  position: relative; }
  .input-icon i {
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%); }

.center-xy {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.zIndex--max {
  z-index: 999999; }

.scroll-x {
  overflow-x: auto; }
  .scroll-x::-webkit-scrollbar {
    -webkit-appearance: none; }
  .scroll-x::-webkit-scrollbar:vertical {
    width: 10px; }
  .scroll-x::-webkit-scrollbar:horizontal {
    height: 10px; }
  .scroll-x::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    /* should match background, can’t be transparent */
    background-color: rgba(0, 0, 0, 0.5); }

.calendar__event-topic {
  width: auto;
  color: #f5f5f5;
  border: none;
  cursor: pointer;
  display: flex; }

.calendar__event-topic-li {
  flex: 1 1 50%;
  text-align: center; }

.calendar__bc-calendar {
  margin: 0;
  margin-top: 30px;
  height: 500px; }

.calendar__categories {
  border: none;
  border-bottom: 2px solid #e5e5e5; }

.calendar__event-link:hover {
  text-decoration: none; }

.calendar__pending-invites {
  height: 250px;
  overflow: auto; }

.calendar__save-button {
  background-color: black;
  color: #f5f5f5;
  border-radius: 0; }
  .calendar__save-button:hover {
    background-color: black;
    color: white; }

.calendar__discard-button {
  background-color: white;
  color: black;
  border-radius: 0; }
  .calendar__discard-button:hover {
    background-color: transparent;
    color: black; }

.calendar__topic-active-class {
  background-color: #0066cc;
  color: #ffffff; }

.calendar__side-nav {
  height: 459px; }

.calendar__description-box {
  border: none;
  background-color: #f5f5f5;
  border-radius: 3px; }

.calendar__edit-partipants__top-buttons {
  border: none;
  background-color: transparent; }
  .calendar__edit-partipants__top-buttons:hover {
    border-bottom: #ef0065; }
  .calendar__edit-partipants__top-buttons:focus {
    outline: none; }
  .calendar__edit-partipants__top-buttons.active {
    outline: none;
    border: none;
    border-bottom: 3px solid #ef0065;
    background-color: transparent; }

.calendar__edit-partipants__primary-div {
  overflow: auto;
  height: 100px; }

.calendar__pt-member-button {
  border: none;
  background-color: white;
  border-radius: 5px; }
  .calendar__pt-member-button:hover {
    background-color: white;
    color: #707070;
    border: none; }

.calendar__primary-card {
  height: 220px;
  overflow: auto; }

.calendar__accept-reject {
  cursor: pointer; }

.calendar__invitation-dialog {
  position: absolute;
  top: 97px;
  right: 100px;
  bottom: 0;
  left: 0;
  z-index: 10040;
  overflow: auto;
  overflow-y: auto; }

.calendar__event-description-box .ql-editor {
  height: 100px; }

.tableheader {
  background: #c4dcf3; }

.tableheadbox {
  min-width: 168px;
  text-align: center;
  padding: 6px;
  font-weight: 400;
  text-align: left; }

.tableheadboxsearch {
  min-width: 330px;
  font-weight: 400;
  align-items: center;
  padding: 6px; }

.borderBottomlightblack {
  border-bottom: 1px solid #f5f5f5; }

.borderBottomBlack {
  border-bottom: 1px solid #000; }

.tableinput {
  border: none;
  outline: none; }

.modalclass {
  display: block;
  width: 1530px; }

.dialogClass {
  margin-left: 200px; }

.customButton {
  border: 2px solid #0066cc;
  border-left-radius: 20px;
  border-top-right-radius: 50px 30px; }

.dotcircle {
  height: 25px;
  width: 25px;
  background-color: #FA3709;
  border-radius: 50%;
  display: inline-block;
  text-align: center; }

.detailsselect {
  width: 50px;
  background: white;
  border: hidden;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  appearance: none; }

.selectfield {
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 0 8px; }

.customselect {
  background: white;
  border: hidden;
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  appearance: none; }

.verticalLine {
  border-left: 2px solid black;
  height: 40px;
  left: 50%;
  margin-left: -3px; }

.titleinput {
  border: 0;
  background: none;
  border-bottom: 1px solid #d6d6d6; }

.titledescription {
  resize: none;
  outline: none;
  border: 0;
  width: 100%;
  border-bottom: 1px solid; }

.heeadingNavpt {
  position: -webkit-sticky;
  position: sticky;
  z-index: 1000;
  top: 42px;
  background: white;
  padding-top: 10px; }

.popoverclass {
  min-width: 300px; }

.tablestudent__studentstatus {
  padding: 3px 0 10px;
  border-radius: 7px;
  transition: -webkit-transform 100ms;
  transition: transform 100ms;
  transition: transform 100ms, -webkit-transform 100ms;
  cursor: pointer;
  position: relative; }
  .tablestudent__studentstatus:hover {
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
    z-index: 1;
    color: blue;
    text-decoration: underline; }

.timePickermodal {
  z-index: 99999 !important; }

.toogel_red {
  background-color: #e81f1f;
  border-color: #e81f1f; }

.toogel_green {
  background-color: #21a533;
  border-color: #21a533; }

.toogel_value_red {
  color: #e81f1f; }

.toogel_value_green {
  color: #21a533; }

.badgePerformnace {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 5px 10px;
  border-radius: 50%;
  background-color: yellow;
  color: white; }

.excel_setting .modal-content {
  width: 75%; }

.message_body .r-rte .ql-editor {
  padding-left: 12px;
  min-height: 130px; }

.pt_settings {
  height: 100%;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
  .pt_settings:hover {
    box-shadow: 0px 0px 12px -2px rgba(0, 0, 0, 0.17);
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

@media print {
  .cv-builder__doc__section {
    -webkit-print-color-adjust: exact; }
  .left-sidenav {
    display: none; }
  .r-nav {
    display: none; }
  .recruitment_nav_collapse {
    display: none; }
  .cv-builder > .row > .col-9 {
    margin-right: auto;
    margin-left: auto; }
  .cv-builder__doc__overlay {
    background-color: rgba(0, 0, 0, 0); } }

.faq .card:not(:first-child) {
  border-top: 0; }

.offer-card .nav-tabs .nav-link {
  font-weight: 600;
  border: none; }

.offer-card .nav-tabs .nav-link.active {
  border: none;
  border-bottom: 2px solid #ef0065; }

.offer-card .nav-tabs .nav-link p::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffcc33; }

.offer-card .nav-tabs .nav-link p.accepted_offer::before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #00b10f; }

.arrow_box {
  position: relative; }

.arrow_box:before {
  bottom: 100%;
  right: 10%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none; }

.arrow_box:before {
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-width: 16px;
  margin-left: -16px; }

.withdraw_deadline {
  width: 9px;
  position: absolute;
  top: -2px; }

.application_offers {
  height: 220px;
  overflow: auto;
  overflow-x: hidden; }

.intraction .timeline {
  list-style: none;
  padding: 0 0 10px;
  margin-bottom: 0;
  position: relative; }
  .intraction .timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: #d6d6d6;
    left: 0%; }
  .intraction .timeline > li {
    position: relative; }
    .intraction .timeline > li > .timeline-panel {
      margin-left: 35px;
      border: 1px solid #d4d4d4;
      border-radius: 3px;
      padding: 15px;
      background-color: #fff;
      position: relative;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175); }
      .intraction .timeline > li > .timeline-panel:before {
        position: absolute;
        top: 26px;
        right: -15px;
        display: inline-block;
        border-top: 15px solid transparent;
        border-left: 15px solid #ccc;
        border-right: 0 solid #ccc;
        border-bottom: 15px solid transparent;
        content: " "; }
      .intraction .timeline > li > .timeline-panel:after {
        position: absolute;
        top: 27px;
        right: -14px;
        display: inline-block;
        border-top: 14px solid transparent;
        border-left: 14px solid #fff;
        border-right: 0 solid #fff;
        border-bottom: 14px solid transparent;
        content: " "; }
    .intraction .timeline > li > .timeline-badge {
      color: #fff;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 30px;
      left: 15px;
      margin-left: -24px;
      background-color: #ffcc33;
      border: 3px solid #fff;
      z-index: 100;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%; }
    .intraction .timeline > li.timeline-inverted > .timeline-panel:before {
      border-left-width: 0;
      border-right-width: 15px;
      left: -15px;
      right: auto; }
    .intraction .timeline > li.timeline-inverted > .timeline-panel:after {
      border-left-width: 0;
      border-right-width: 14px;
      left: -14px;
      right: auto; }

.notes .role--select {
  background-color: #0066cc !important;
  color: #ffffff !important; }

.intraction .date-section {
  margin-top: -20px !important;
  margin-bottom: 10px; }

.intraction .tag {
  padding: 5px 15px !important; }

.settings__comming-soon {
  font-size: 9px; }

.settings__settings-icon {
  font-size: 17px; }

.settings__button-color {
  color: #124B84; }

.settings__button-cursor {
  cursor: pointer; }

.settings__multifield-update {
  background-color: #f5f5f5;
  border: 0.5px solid #e5e5e5;
  font-weight: 300;
  padding: 5px;
  outline: none; }

.settings__info-icon {
  width: 10px;
  position: absolute;
  bottom: 9px; }

.settings__button {
  position: relative; }

.settings__email .r-multifield {
  margin: 0;
  padding: 0; }
  .settings__email .r-multifield__list {
    margin-bottom: 0; }

.settings__email .r-inputfield {
  padding: .4rem;
  font-size: 1.2rem;
  border: 0; }

.settings__excel_checkbox {
  vertical-align: middle; }

.help_section {
  position: fixed;
  bottom: 150px;
  right: 22px; }
  .help_section__float {
    cursor: pointer;
    width: 50px;
    height: 50px;
    bottom: 40px;
    right: 22px;
    background-color: black;
    color: white;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: 1000;
    -webkit-animation: bot-to-top 2s ease-out;
            animation: bot-to-top 2s ease-out; }
  .help_section__ul-style ul {
    padding: 10px;
    list-style: none; }
  .help_section__ul-style ul li {
    border-bottom: 1px solid black;
    padding: 5px;
    font-size: 11px;
    cursor: pointer; }

@-webkit-keyframes bot-to-top {
  0% {
    bottom: -40px; }
  50% {
    bottom: 40px; } }

@keyframes bot-to-top {
  0% {
    bottom: -40px; }
  50% {
    bottom: 40px; } }

@-webkit-keyframes scale-in {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

@keyframes scale-in {
  from {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

.email_campaign__modal-checkbox {
  display: contents; }

.email_campaign__campaign_allignment {
  vertical-align: middle; }

.email_campaign__campgin-modal {
  overflow-y: scroll;
  max-height: -webkit-fill-available;
  overflow-x: hidden; }

.email_campaign__select-all {
  vertical-align: text-bottom; }

.recruitment-drive {
  font-size: x-large;
  font-family: poppins; }

.college__collegeprofile__image {
  height: 150px;
  width: auto;
  border-radius: 3px; }
  .college__collegeprofile__image__container {
    position: relative; }
    .college__collegeprofile__image__container:hover .college__profile__image--edit {
      opacity: 1; }

.college_navSide {
  border-radius: 3px;
  color: #0066cc;
  border: 0.5px solid rgba(169, 175, 209, 0.19);
  overflow-x: hidden;
  max-height: calc(100vh - 80px);
  transition: all 250ms ease-in-out 0s; }

.college_text-end {
  text-align: end; }

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important; }

.recharts-wrapper, .recharts-wrapper svg {
  width: 100% !important; }

